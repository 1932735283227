import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetService } from "../../../../services/ConstantService";
import { API_URL } from "../../../../services/ApiService";
import { MoonLoader } from "react-spinners";
import { showErrorAlert } from "../../../../Helper/SweetAlert";
import { format, parseISO } from "date-fns";
import IconUser from "../../../../assets/img/IconUser.png";
import { ImageURL } from "../../../../Environment/Environment";

export default function ViewSlip() {
  const [loading, setLoading] = useState(false);
  const [DataObj, setDataObj] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getSlipDetails();
  }, []);

  function getSlipDetails() {
    setLoading(true);
    GetService(`${API_URL.SLIP_DETAIL}?id=${params?.id}`).then(
      (res) => {
        if (res.data.status) {
          console.log(res.data.data);
          setDataObj(res?.data?.data);
        } else {
          showErrorAlert(res.data?.message);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        showErrorAlert(err.data?.message);
      }
    );
  }

  const navigatetogrpList = (id) => {
    navigate(`/panel/view_group/${id}/transaction`);
  };
  const navigatetoGrpWltList = (id, type) => {
    if (type === "wallet") {
      navigate(`/panel/view_wallet/${id}/transaction`);
    } else {
      navigate(`/panel/view_group/${id}/transaction`);
    }
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12 d-flex">
              <h3 className="fs-30">Slip Details</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <Link
                    to={"/panel/slips"}
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Slips
                  </Link>
                  <span className="breadcrumb-item active">Details</span>
                </ol>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row px-3">
                    {!loading && (
                      <>
                        <div className="col-8">
                          <div className="d-flex justify-content-between">
                            <p className="fs-16 font-weight-500">
                              {" "}
                              {DataObj?.slip
                                ? `Slip#${DataObj?.slip}`
                                : DataObj?.slip_id}{" "}
                              <span className="ml-2">●</span>{" "}
                              <span className="ml-2 font-weight-normal">
                                {DataObj?.Value?.toLocaleString()}{" "}
                                {DataObj?.Currency}
                              </span>
                              <p className="fs_12 text-muted  mb-0 mt-2">
                                {DataObj?.createdAt &&
                                  format(
                                    parseISO(DataObj?.createdAt),
                                    "EEE dd-MMM-yyyy, HH:mm"
                                  )}
                              </p>
                            </p>

                            <div className="">
                              {DataObj?.status === "Pending" ? (
                                <span className="btn btn-sm btn-warning align-items-center">
                                  Pending
                                </span>
                              ) : DataObj?.status === "Win" ? (
                                <span className="btn btn-sm btn-success align-items-center">
                                  Win
                                </span>
                              ) : DataObj?.status === "Lost" ? (
                                <span className="btn btn-sm btn-danger align-items-center">
                                  Lost
                                </span>
                              ) : DataObj?.status === "Void" ? (
                                <span className="btn btn-sm btn-info align-items-center">
                                  Void
                                </span>
                              ) : DataObj?.status === "Win50%" ? (
                                <span className="btn btn-sm btn-secondary align-items-center">
                                  Win 50%
                                </span>
                              ) : DataObj?.status === "Lost50%" ? (
                                <span className="btn btn-sm btn-secondary align-items-center">
                                  Lost 50%
                                </span>
                              ) : DataObj?.status === "Error" ? (
                                <span className="btn btn-sm btn-danger align-items-center">
                                  Error
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>

                          <div className="">
                            <table class="table table-hover">
                              <thead className="overwrite-thead">
                                <tr>
                                  <th className="overwrite-th">ID</th>
                                  <th className="text-left pl-5 w-50 overwrite-th">
                                    EVENT
                                  </th>
                                  <th className="overwrite-th">ODD</th>
                                  <th className="overwrite-th">DESCRIPTION</th>
                                </tr>
                              </thead>
                              <tbody className="overwrite-tbody">
                                {DataObj?.Lines?.length &&
                                  DataObj?.Lines?.map((data, i) => (
                                    <tr key={i}>
                                      <td style={{ width: "90px" }}>
                                        {data?.EventDetails?.Id
                                          ? data?.EventDetails?.Id.substring(
                                              data?.EventDetails?.Id?.length - 6
                                            )
                                          : "-"}{" "}
                                      </td>
                                      <td className="text-left w-50 override-padding">
                                        <p className="fs_12 mb-1">
                                          {data?.EventDetails &&
                                            format(
                                              parseISO(
                                                data?.EventDetails?.Date
                                              ),
                                              "dd-MM-yy, HH:mm"
                                            )}
                                        </p>

                                        <p className="font-weight-bold mb-0">
                                          {data?.EventDetails ? (
                                            data?.EventDetails?.Sport?.Name ===
                                            "Football" ? (
                                              <i
                                                class="fa-regular fa-futbol"
                                                style={{ fontSize: "large" }}
                                              ></i>
                                            ) : (
                                              <i
                                                class="fa-solid fa-basketball"
                                                style={{ fontSize: "large" }}
                                              ></i>
                                            )
                                          ) : (
                                            ""
                                          )}{" "}
                                          <span className="ml-1">
                                            {data?.MarketRef === "moneyline" &&
                                            data?.SelectionRef === "home"
                                              ? `${
                                                  data?.EventDetails?.Name.split(
                                                    " - "
                                                  )[0] || "Team 1"
                                                }`
                                              : data?.MarketRef ===
                                                  "moneyline" &&
                                                data?.SelectionRef === "draw"
                                              ? "Draw"
                                              : data?.MarketRef ===
                                                  "moneyline" &&
                                                data?.SelectionRef === "away"
                                              ? `${
                                                  data?.EventDetails?.Name.split(
                                                    " - "
                                                  )[1] || "Team 2"
                                                }`
                                              : data?.MarketRef === "spread" &&
                                                data?.SelectionRef === "home"
                                              ? `${
                                                  data?.EventDetails?.Name.split(
                                                    " - "
                                                  )[0] || "Team 1"
                                                } (${data?.Points / 100})`
                                              : data?.MarketRef === "spread" &&
                                                data?.SelectionRef === "away"
                                              ? `${
                                                  data?.EventDetails?.Name.split(
                                                    " - "
                                                  )[1] || "Team 2"
                                                } (${data?.Points / 100})`
                                              : data?.MarketRef === "total"
                                              ? `${data?.SelectionRef} (${
                                                  data?.Points / 100
                                                })`
                                              : data?.MarketRef === "dnb" &&
                                                data?.SelectionRef === "home"
                                              ? `${
                                                  data?.EventDetails?.Name.split(
                                                    " - "
                                                  )[0] || "Team 1"
                                                }`
                                              : data?.MarketRef === "dnb" &&
                                                data?.SelectionRef === "away"
                                              ? `${
                                                  data?.EventDetails?.Name.split(
                                                    " - "
                                                  )[1] || "Team 2"
                                                }`
                                              : data?.MarketRef === "dc" &&
                                                data?.SelectionRef ===
                                                  "home-draw"
                                              ? `${
                                                  data?.EventDetails?.Name.split(
                                                    " - "
                                                  )[0] || "Team 1"
                                                } or Draw`
                                              : data?.MarketRef === "dc" &&
                                                data?.SelectionRef ===
                                                  "home-away"
                                              ? `${
                                                  data?.EventDetails?.Name.split(
                                                    " - "
                                                  )[0] || "Team 1"
                                                } or ${
                                                  data?.EventDetails?.Name.split(
                                                    " - "
                                                  )[1] || "Team 2"
                                                }`
                                              : data?.MarketRef === "dc" &&
                                                data?.SelectionRef ===
                                                  "draw-away"
                                              ? `Draw or ${
                                                  data?.EventDetails?.Name.split(
                                                    " - "
                                                  )[1] || "Team 2"
                                                }`
                                              : data?.MarketRef === "ggng" &&
                                                data?.SelectionRef === "gg"
                                              ? "Goal"
                                              : data?.MarketRef === "ggng" &&
                                                data?.SelectionRef === "ng"
                                              ? "No Goal"
                                              : ""}
                                          </span>
                                        </p>

                                        <p className=" fs_12 mb-0">
                                          {data?.EventDetails
                                            ? `${data?.EventDetails?.Region?.Name} - ${data?.EventDetails?.League?.Name}`
                                            : data?.leagueName}
                                        </p>
                                        <p className="fa-1x  mb-0">
                                          {data?.EventDetails
                                            ? data?.EventDetails?.Name
                                            : data?.name}{" "}
                                        </p>
                                        <p className=" text-uppercase mb-0">
                                          {data?.MarketRef === "moneyline"
                                            ? "1X2"
                                            : data?.MarketRef}
                                        </p>
                                      </td>
                                      <td>{data?.Price / 100 || "-"}</td>
                                      <td>
                                        {DataObj?.main_group?.description ||
                                          "-"}
                                      </td>{" "}
                                      {/* Added this line */}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          <hr />

                          <div className="row">
                            <div className="col-5 card groupPersonalCard py-3 px-2 rounded d-flex flex-column">
                              <h5>Tax,Action & Free Roll Summery</h5>
                              <div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <input
                                      type="checkbox"
                                      name=""
                                      id="tax"
                                      className="form-control form-control-sm"
                                    />{" "}
                                    <label htmlFor="tax" className="mb-0 ml-2">
                                      Tax
                                    </label>
                                  </div>
                                  <div className="w-25">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      placeholder="%"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                  <div className="d-flex align-items-center">
                                    <input
                                      type="checkbox"
                                      name=""
                                      id="free"
                                      className="form-control form-control-sm"
                                    />{" "}
                                    <label htmlFor="free" className="mb-0 ml-2">
                                      Free&nbsp;roll
                                    </label>
                                  </div>
                                  <div className="w-25">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      placeholder="%"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                  <div className="d-flex align-items-center">
                                    <input
                                      type="checkbox"
                                      name=""
                                      id="action"
                                      className="form-control form-control-sm"
                                    />{" "}
                                    <label
                                      htmlFor="action"
                                      className="mb-0 ml-2"
                                    >
                                      Action
                                    </label>
                                  </div>
                                  <div className="w-25">
                                    <input
                                      type="text"
                                      className="form-control form-control-sm"
                                      placeholder="%"
                                    />
                                  </div>
                                </div>
                                <hr />
                                <label>Action Unpayed</label> <br />
                                <label>Ecpense Unpayed</label>
                              </div>

                              <div className="mt-auto">
                                <button className="btn btn-sm btn-primary rounded w-100">
                                  Apply
                                </button>
                              </div>
                            </div>
                            <div className="col-7">
                              <div className="groupPersonalCard rounded card p-3">
                                <h5>Slip Summery</h5>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                  <label>Total Odds</label>
                                  <p className="mr-5">{DataObj?.total_odds}</p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                  <label>Amount</label>
                                  <p className="mr-5">
                                    {DataObj?.Value?.toLocaleString()}{" "}
                                    {DataObj?.Currency}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                  <label>Potential Win</label>
                                  <p className="mr-5">
                                    {DataObj?.potential_win?.toLocaleString()}{" "}
                                    {DataObj?.Currency}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                  <label>Profit/Loss</label>
                                  <p
                                    className={`mr-5 ${
                                      DataObj?.profit_loss > 0
                                        ? "text-success"
                                        : DataObj?.profit_loss < 0
                                        ? "text-danger"
                                        : DataObj?.profit_loss === 0
                                        ? "text-secondary"
                                        : ""
                                    }`}
                                  >
                                    {DataObj?.profit_loss?.toLocaleString()}{" "}
                                    {DataObj?.Currency}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                  <label>Taxes</label>
                                  <p className="mr-5">-</p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                  <label>Action</label>
                                  <p className="mr-5">-</p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-1">
                                  <label>Free Roll</label>
                                  <p className="mr-5">-</p>
                                </div>
                                <div className="d-flex bg-inverse-secondary justify-content-between align-items-center mt-1">
                                  <label className="m-2 text-dark">Net</label>
                                  <p
                                    className={`mr-5 ${
                                      DataObj?.profit_loss > 0
                                        ? "text-success"
                                        : DataObj?.profit_loss < 0
                                        ? "text-danger"
                                        : DataObj?.profit_loss === 0
                                        ? "text-secondary"
                                        : ""
                                    }`}
                                  >
                                    {DataObj?.profit_loss} {DataObj?.Currency}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-4 p-0 d-flex flex-column">
                          <div className="card groupPersonalCard rounded p-3">
                            <div className="d-flex justify-content-left">
                              <div style={{ height: "50px", width: "50px" }}>
                                <img
                                  src={
                                    DataObj?.main_group?.image
                                      ? ImageURL + DataObj?.main_group?.image
                                      : IconUser
                                  }
                                  alt="..."
                                  className="h-100 w-100"
                                />
                              </div>
                              <div className="ml-2">
                                <p>
                                  {DataObj?.main_group?.name}{" "}
                                  <span
                                    className={`ml-1 ${
                                      DataObj?.main_group?.tag === "Passivity"
                                        ? "badge-dot-rd"
                                        : DataObj?.main_group?.tag ===
                                          "Activity"
                                        ? "badge-dot-gr"
                                        : ""
                                    }`}
                                  ></span>
                                </p>
                                <p>
                                  Referent :{" "}
                                  {DataObj?.main_group?.referent?.name}
                                </p>
                              </div>
                            </div>
                            <p className="text-muted ml-5 my-2">
                              <span>
                                <i class="fa fa-phone" aria-hidden="true"></i>
                              </span>
                              &nbsp;
                              {DataObj?.main_group?.referent?.phone}
                            </p>

                            <div className=" ">
                              <p className="text-muted my-2">
                                COLLABORATORS :{" "}
                              </p>
                              {DataObj?.main_group?.Collaborators?.length ? (
                                DataObj?.main_group?.Collaborators?.map(
                                  (data, i, array) => (
                                    <li className="" key={i}>
                                      {data?.mainUser?.name}
                                      {i !== array.length - 1 && <span>,</span>}
                                    </li>
                                  )
                                )
                              ) : (
                                <p className=" ">N/O</p>
                              )}
                            </div>

                            <p className="text-muted my-2">
                              PROFILE :{" "}
                              {DataObj?.main_group?.profile?.profile_name}
                            </p>

                            <p className="text-muted mt-3">
                              {DataObj?.main_group?.description}
                            </p>
                            <hr />

                            <div className="d-flex justify-content-between align-items-center mt-3">
                              <label>
                                {DataObj?.main_group?.name}{" "}
                                <span
                                  className={`ml-1 ${
                                    DataObj?.main_group?.tag === "Passivity"
                                      ? "badge-dot-rd"
                                      : DataObj?.main_group?.tag === "Activity"
                                      ? "badge-dot-gr"
                                      : ""
                                  }`}
                                ></span>
                              </label>
                              <button
                                className="btn btn-primary rounded btn-sm"
                                onClick={() =>
                                  navigatetogrpList(DataObj?.group_id)
                                }
                              >
                                <i class="fa-solid fa-right-left"></i> See
                                Transaction
                              </button>
                            </div>

                            {DataObj?.attach_id && (
                              <div className="d-flex justify-content-between align-items-center my-3">
                                <label>
                                  {DataObj?.attach_group?.name}{" "}
                                  <span
                                    className={`ml-1 ${
                                      DataObj?.attach_group?.tag === "Passivity"
                                        ? "badge-dot-rd"
                                        : DataObj?.attach_group?.tag ===
                                          "Activity"
                                        ? "badge-dot-gr"
                                        : ""
                                    }`}
                                  ></span>
                                </label>
                                <button
                                  className="btn btn-primary rounded btn-sm"
                                  onClick={() =>
                                    navigatetoGrpWltList(
                                      DataObj?.attach_id,
                                      DataObj?.attach_group?.type
                                    )
                                  }
                                >
                                  <i class="fa-solid fa-right-left"></i> See
                                  Transaction
                                </button>
                              </div>
                            )}
                          </div>

                          <div className="d-flex justify-content-center mt-auto">
                            <button className="btn btn-sm btn-danger rounded mt-2">
                              <i class="fa-solid fa-trash"></i> Delete Slip
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    {loading ? (
                      <div className="LoaderSpinner">
                        <MoonLoader
                          color={"#003bff"}
                          loading={loading}
                          speedMultiplier={0.6}
                          size={50}
                        />
                        <div>Loading... Please wait.</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* / DELETE MODAL-------------------- / */}
      <div
        className="modal fade"
        id="DeleteModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete <span className="text-capitalize">{params?.type}</span> !
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6> Are you sure you want to delete this Slip ? </h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                data-dismiss="modal"
                id="closeDeleteModal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-sm btn-danger"
                // onClick={DeleteFunc}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* / DELETE MODAL- End ------------------- / */}
    </div>
  );
}

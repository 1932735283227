/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Pagination from "../../../../Helper/Pagination";
import Switch from "@mui/material/Switch";
import { API_URL } from "../../../../services/ApiService";
import { GetService, PostService } from "../../../../services/ConstantService";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import TooltipComponent from "../../Layout/Comman_Tooltip/Tooltip";
import { format, parseISO } from "date-fns";
import TablePagination from "@mui/material/TablePagination";

export default function Wallets() {
  const [groupData, setgroupData] = useState();
  const [totalPages, setTotalPages] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [S_No_Count, setCount] = useState(1);
  const [userLimit, setUserLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [playerlist, setplayerlist] = useState();
  const [referencelist, setreferencelist] = useState();
  const [profilolist, setprofilolist] = useState();
  const [SearchReferent, setSearchReferent] = useState("");
  const [SearchPlayer, setSearchPlayer] = useState("");
  const [SearchCategory, setSearchCategory] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    setPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value);
    // console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserLimit(event.target.value);
    setCurrentPage(0);
    setPage(0);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e - 1);
  };
  function getgrouplisting() {
    setLoading(true);
    GetService(
      `${
        API_URL.GROUP_LISTING
      }?page=${currentPage}&size=${userLimit}&search=${encodeURIComponent(
        searchValue
      )}&type=wallet&ref_id=${SearchReferent}&player_id=${SearchPlayer}&category=${SearchCategory}`
    ).then((res) => {
      setLoading(false);
      console.log(res);
      if (res?.data?.status) {
        setgroupData(res?.data?.data?.list || []);
        setTotalPages(res.data?.data?.totalPages || 0);
        setTotal(res?.data?.data?.totalItems || 0);
      }
    });
  }

  const changeStatus = async (id, status) => {
    const data = {
      id: id,
      status: status === 1 ? 0 : 1,
    };

    PostService(API_URL.CHANGE_STATUS_GROUP, data).then(
      (res) => {
        if (res.data.status) {
          showSuccessAlert(res?.data?.message, getgrouplisting);
        } else {
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
      }
    );
  };

  useEffect(() => {
    getgrouplisting();
  }, [currentPage, searchValue, SearchReferent, SearchPlayer,SearchCategory,userLimit]);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const getHeaderClassName = (columnName) => {
    if (columnName === sortColumn) {
      return `sorting_${sortDirection}`;
    }
    return "sorting";
  };

  const sortedData = groupData?.slice().sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortDirection === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const ConnectedGroups = (item) => {
    console.log(item);
    const groupsArray = [];
    item?.group_detail &&
      item?.group_detail.map((data) => groupsArray.push(data));
    item?.groups_detail &&
      item?.groups_detail.map((data) => groupsArray.push(data));

    return groupsArray;
  };

  const ExportToCSV = () => {
    const ExportArray = [];
    groupData.map((data) =>{
    const groupsArray = [];
      data?.group_detail &&
        data?.group_detail.map((data) => groupsArray.push(data));
      data?.groups_detail &&
        data?.groups_detail.map((data) => groupsArray.push(data));
      ExportArray.push({
        Id: data?.id,
        Date: data?.createdAt
          ? format(parseISO(data?.createdAt), "dd-MM-yyyy HH:mm")
          : "NO",
          "A/P": data?.tag,
          Status: data?.status === 1 ? "Active" : "Inactive", 
          Name: data?.name,
          Category : data?.category || 'NO',
          Referent: data?.referent?.name || "NO",
          Player: data?.player?.name || "NO",
        Collaborators:
        data?.Collaborators.map(
          (entry, i) => `(${i + 1})${entry?.mainUser?.name}`
        ).join(" ") || "N/O",
      Connected:
        groupsArray
          .map(
            (entry, i) =>
              `(${i + 1})${
                entry?.satellite_group?.name || entry?.group_main_detail?.name
              }`
          )
          .join(" ") || "N/O",
      Current_Balance:
        data?.groupcurrency
          .map((entry, i) => `(${i + 1})${entry?.amount} ${entry?.currency}`)
          .join(" ") || "N/O",
      Description: data?.description,
       
      })
  });

    const keys = Object.keys(ExportArray[0]);

    // Create CSV content with keys as headers and data values as rows
    const csvContent =
      "data:text/csv;charset=utf-8," +
      keys.join(",") +
      "\n" +
      ExportArray.map((row) => keys.map((key) => row[key]).join(",")).join(
        "\n"
      );

    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "Wallets_List.csv");

    // Append the link to the body
    document.body.appendChild(link);

    // Click the link programmatically to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  function getreferentlisting() {
    GetService(API_URL?.REFERENTS_LISTING_WITHOUT_PAGINATION).then((res) => {
      console.log(res);
      if (res?.data?.status) {
        setreferencelist(res?.data?.data);
      }
    });
  }

  useEffect(() => {
    getreferentlisting();
    getplayerlisting();
    getprofilelisting();
  }, []);

  function getplayerlisting() {
    GetService(API_URL.PLAYERS_LISTING_WITHOUT_PAGINATION).then((res) => {
      if (res?.status) {
        setplayerlist(res?.data?.data);
      }
    });
  }

  function getprofilelisting() {
    GetService(API_URL.PROFILES_LISTING_WITHOUT_PAGINATION).then((res) => {
      if (res?.status) {
        setprofilolist(res?.data?.data);
      }
    });
  }

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-6 d-flex">
              <h3 className="fs-30 text-secondprimary">Wallets</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <span className="breadcrumb-item active">Wallets</span>
                </ol>
              </span>
            </div>
            <div className="col-6 pr-5">
              <div className="d-flex justify-content-end">
                <NavLink to={"/panel/new_wallet"}>
                  <div
                    className="btn  bg-secondprimary rounded-sm text-white"
                    style={{ minWidth: "180px" }}
                  >
                    <i className="fa-solid fa-plus mr-1"></i>
                    Add Wallet
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex mb-4">
                    <div className="col-md-4 pl-2">
                      <label htmlFor="search-input" className="search-label">
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </label>
                      <input
                        type="search"
                        id="search-input"
                        className="form-control pl-5 form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setsearchValue(e.target.value)}
                      />
                    </div>

                    <div className="col-2">
                      <select
                        onChange={(e) => setSearchReferent(e.target.value)}
                        className={`form-control form-control-sm`}
                      >
                        <option value={""}>Select Referent</option>
                        {referencelist &&
                          referencelist?.map((item, i) => (
                            <option value={item?.id} key={i}>
                              {item?.name}
                            </option>
                          ))}
                      </select>
                    </div> 
                    <div className="col-2">
                      <select
                        onChange={(e) => setSearchPlayer(e.target.value)}
                        className={`form-control form-control-sm`}
                      >
                        <option value={""}>Select Player</option>
                        {playerlist &&
                          playerlist?.map((item, i) => (
                            <option value={item?.id} key={i}>
                              {item?.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-2">
                      <select
                        onChange={(e) => setSearchCategory(e.target.value)}
                        className={`form-control form-control-sm`}
                      >
                        <option value={""}>Select Category</option>
                        <option value="Cash">Cash</option>
                        <option value="Bank">Bank</option>
                        <option value="Crypto">Crypto</option>
                        <option value="Credit/Debit">Credit/Debit</option>
                      </select>
                    </div>

                    <div className="col-md-2 ml-auto">
                      <button
                        className="badge badge-outline-primary fa-1x rounded float-right"
                        onClick={ExportToCSV}
                      >
                        <i className="fa fa-upload" aria-hidden="true"></i>{" "}
                        Export
                      </button>
                    </div>
                  </div>
                  <div className="row  px-3">
                    <div class="table-responsive  pb-2">
                      {!loading && (
                        <table class="table  dataTable no-footer table-hover">
                          <thead>
                            <tr>
                              <th className="pr-2">S.No</th>
                              {/* <th
                                className={getHeaderClassName("id")}
                                onClick={() => handleSort("id")}
                              >
                                ID
                              </th> */}
                              <th className="pr-2">A/P</th>
                              <th
                                className={getHeaderClassName("status")}
                                onClick={() => handleSort("status")}
                              >
                                Status
                              </th>

                              <th className="pr-0">Category</th>

                              <th
                                className={`${getHeaderClassName("name")} px-4`}
                                onClick={() => handleSort("name")}
                              >
                                Name
                              </th>
                              <th
                                className="px-3"
                                // className={`${getHeaderClassName(
                                //   "user"
                                // )} text-left`}
                                // onClick={() => handleSort("user")}
                              >
                                Referent
                              </th>

                              <th
                                className="px-3"
                                // className={`${getHeaderClassName(
                                //   "player"
                                // )} text-left`}
                                // onClick={() => handleSort("player")}
                              >
                                Player
                              </th>

                              <th
                                className="px-0"
                                // className={getHeaderClassName("group_detail")}
                                // onClick={() => handleSort("group_detail")}
                              >
                                Connected
                              </th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {groupData &&
                              sortedData?.map((item, i) => (
                                <tr key={i}>
                                  <td className="">
                                    <span className="">
                                      {i + currentPage * userLimit + 1}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        item?.tag === "Activity"
                                          ? "badge bg-inverse-success text-black"
                                          : item?.tag === "Passivity"
                                          ? "badge bg-inverse-danger text-black"
                                          : ""
                                      }
                                    >
                                      {item?.tag}
                                    </span>
                                  </td>

                                  {/* <td className="text-left">{item?.id}</td> */}

                                  <td>
                                    <span
                                      className={
                                        item?.status === 1
                                          ? "badge badge-success"
                                          : "badge badge-danger"
                                      }
                                    >
                                      {item?.status ? "Active" : "Inactive"}
                                    </span>
                                  </td>
                                  <td>
                                    {item?.category === "Bank" ? (
                                      <i
                                        class="fa fa-university fa-lg"
                                        aria-hidden="true"
                                      ></i>
                                    ) : item?.category === "Crypto" ? (
                                      <i class="fa-brands fa-bitcoin fa-lg"></i>
                                    ) : item?.category === "Cash" ? (
                                      <i class="fa-solid fa-money-bill fa-lg"></i>
                                    ) : item?.category === "Credit/Debit" ? (
                                      <i
                                        class="fa fa-credit-card fa-lg"
                                        aria-hidden="true"
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td className=" ">
                                    <Link
                                      to={`/panel/view_wallet/${item?.id}/personal_data`}
                                    >
                                      {item?.name}
                                    </Link>
                                  </td>
                                  <td className=" ">
                                    <Link
                                      to={`/panel/view_user/${item?.ref_id}/referent`}
                                    >
                                      {item?.referent
                                        ? item?.referent?.name
                                        : "NO"}
                                    </Link>
                                  </td>

                                  <td className=" ">
                                    <Link
                                      to={`/panel/view_user/${item?.player_id}/player`}
                                    >
                                      {item?.player ? item?.player?.name : "NO"}
                                    </Link>
                                  </td>
                                  <td>
                                    {(item?.group_detail?.length > 0 ||
                                      item?.groups_detail?.length > 0) && (
                                      <TooltipComponent
                                        data={ConnectedGroups(item)}
                                      ></TooltipComponent>
                                    )}
                                    {item?.group_detail?.length === 0 &&
                                      item?.groups_detail?.length === 0 && (
                                        <span>NO</span>
                                      )}
                                  </td>
                                  <td>
                                    <span
                                      className="mx-2"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <Link
                                        to={`/panel/view_wallet/${item?.id}/transaction`}
                                      >
                                        <i
                                          class="fa fa-exchange"
                                          aria-hidden="true"
                                          title="Transaction"
                                        ></i>
                                      </Link>
                                    </span>
                                    <span
                                      title="Edit"
                                      style={{ cursor: "pointer" }}
                                      className="mx-2"
                                    >
                                      <Link
                                        to={`/panel/update_wallet/${item?.id}`}
                                      >
                                        <i class="fa-solid fa-pen"></i>
                                      </Link>
                                    </span>
                                    <span className="toggle-switch">
                                      <Switch
                                        checked={item?.status}
                                        size="small"
                                        color="primary"
                                        onChange={() => {
                                          changeStatus(item?.id, item?.status);
                                        }}
                                        inputProps={{
                                          "aria-label": "toggle switch",
                                        }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            {groupData?.length === 0 && (
                              <tr>
                                <td colSpan="11" className="text-center">
                                  <h6 className="p-3"> No Data Found </h6>
                                </td>
                              </tr>
                            )}
                            {/* <tr>
                              <td colSpan={11}>
                                <div className="d-flex justify-content-end">
                                  <Link
                                    className="add_circle bg-primary"
                                    to={"/panel/new_wallet"}
                                  >
                                    <i class="fa-solid fa-plus text-white"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      )}
  </div>
                      {loading ? (
                        <div className="LoaderSpinner">
                          <MoonLoader
                            color={"#003bff"}
                            loading={loading}
                            speedMultiplier={0.6}
                            size={50}
                          />
                          <div>Loading... Please wait.</div>
                        </div>
                      ) : (
                        ""
                      )}

<div className="mt-4 ml-auto">
                        {/* <Pagination
                          counting={currentPage * userLimit}
                          totaldata={total}
                          pagecount={totalPages}
                          onChangePage={handlePageClick}
                          activePage={currentPage}
                        /> */}
                         <TablePagination
                          component="div"
                          count={total}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

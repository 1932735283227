import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

export function showSuccessAlert(message, callback) {
    Swal.fire({
      title: "Success!",
      text: message,
      icon: "success",
      confirmButtonColor: "#1F4CE0",
      confirmButtonText: "Close",
      allowOutsideClick: false,
    }).then((result) => {
        if (result.isConfirmed && callback) {
          callback();
        }
      });
  }
  
  export function showErrorAlert(message, callback) {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonColor: "#FF5733",
      confirmButtonText: "Close",
      allowOutsideClick: false,
    }).then((result) => {
        if (result.isConfirmed && callback) {
          callback();
        }
      });
  }

  export function showWarningAlert(message, callback) {
    Swal.fire({
      title: "Warning!",
      text: message,
      icon: "warning",
      confirmButtonColor: "#FF5733",
      confirmButtonText: "Close",
      allowOutsideClick: false,
    }).then((result) => {
        if (result.isConfirmed && callback) {
          callback();
        }
      });
  }

  export function showDeleteConfirmation(itemName,callback) {
    Swal.fire({
        title: `Delete ${itemName}`,
        text: `Are you sure you want to delete this ${itemName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#1F4CE0",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed && callback) {
        callback();
      }
    });
  }

  export function showtoasterSuccess(message) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: message
    })
    return Toast
  }

  export function  showtoastererror(message) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'error',
      title: message
    })
    return Toast
  }
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import Pagination from "../../../../Helper/Pagination";
import Switch from "@mui/material/Switch";
import { API_URL } from "../../../../services/ApiService";
import { GetService, PostService } from "../../../../services/ConstantService";
import { useFormik } from "formik";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import GroupSearch from "../../Layout/SearchGroup/SearchGroup";
import TooltipComponent from "../../Layout/Comman_Tooltip/Tooltip";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input"; 
import { format, parseISO } from "date-fns";
import TablePagination from "@mui/material/TablePagination";

export default function Player() {
  const [playerData, setPlayerData] = useState();
  const [totalPages, setTotalPages] = useState();
  const [currencylist, setcurrencylist] = useState();
  const [profilolist, setprofilolist] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [S_No_Count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formsubmit, setformsubmit] = useState(false);
  const [selectedState, setselectedState] = useState("");
  const [selectedOptions, setSelectedOptions] = useState("");
  const resetStateRef = useRef(false);
  const [userLimit, setUserLimit] = useState(10);
  const [searchValue, setsearchValue] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    setPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value);
    // console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserLimit(event.target.value);
    setCurrentPage(0);
    setPage(0);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e - 1);
  };

  const inputdata = useRef("");
  const component = useRef("player");

  const handleReset = () => {
    formik.resetForm();
    inputdata.current = "";
    setformsubmit(false);
    setSelectedOptions("");
    handleResetButtonClick();
    setDisabledField(false);
  };

  const changeStatus = async (id, status) => {
    const data = {
      id: id,
      status: status === 1 ? 0 : 1,
    };

    PostService(API_URL.CHANGE_STATUS_PLAYER, data).then(
      (res) => {
        if (res.data.status) {
          showSuccessAlert(res?.data?.message, getplayerlisting);
        } else {
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
      }
    );
  };
  const handleResetButtonClick = () => {
    resetStateRef.current = !resetStateRef.current;
  };
  const handleSelectedOptionsChange = (options) => {
    setSelectedOptions(options);
  };
  function getCurrencylisting() {
    GetService(API_URL.GET_CURRENCY).then((res) => {
      if (res?.status) {
        setcurrencylist(res?.data?.data);
      }
    });
  }

  function getplayerlisting() {
    setLoading(true);
    GetService(
      `${API_URL.PLAYERS_LISTING}?page=${currentPage}&size=${userLimit}&search=${encodeURIComponent(searchValue)}`
    ).then((res) => {
      setLoading(false);
      if (res?.status) {
        setPlayerData(res?.data?.data?.list || []);
        setTotalPages(res.data?.data?.totalPages || 0);
        setTotal(res?.data?.data?.totalItems || 0);
      }
    });
  }
  function getprofilelisting() {
    GetService(API_URL.GET_PROFILO).then((res) => {
      if (res?.status) {
        setprofilolist(res?.data?.data?.list);
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      phone: "",
      profile_id: "",
      description: "",
      currency: "",
      amount: "",
      status: 1,
    },
    validate: (values) => {
      console.log(values);
      const errors = {};

      // Validate 'name' field
      if (!values.name) {
        errors.name = "Name is required";
      }

      // Validate 'email' field
      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      // Validate 'phone' field
      if (!values.phone) {
        errors.phone = "Phone is required";
      } else if (CheckTelLength(values.phone)) {
        errors.phone = "Phone length";
      }

      if (!selectedState && !values.password) {
        errors.password = "Password is required";
      }

      if (!selectedState) {
        if (!values.confirm_password) {
          errors.confirm_password = "Confirm Password is required";
        } else if (values.confirm_password !== values.password) {
          errors.confirm_password = "Password mismatch";
        }
      }

      // Validate 'description' field
      if (!values.description) {
        errors.description = "description is required";
      }
      return errors;
    },

    onSubmit: (values) => {
      console.log(values);
      setformsubmit(true);
      if (!formik.isValid) {
        return;
      }
      const route = selectedState
        ? API_URL.UPDATE_PLAYERS
        : API_URL.CREATE_PLAYER;
      if (selectedState) {
        values.id = selectedState?.id;
      }
      const selectedGroupId = [];
      if (selectedOptions?.length > 0) {
        for (let i of selectedOptions) {
          selectedGroupId.push(i?.id);
        }
        values.group = selectedGroupId;
      }
      PostService(route, values).then(
        (res) => {
          if (res?.data?.status) {
            showSuccessAlert(res?.data?.message, getplayerlisting);
            document.getElementById("closeModal").click();
          } else {
            showErrorAlert(res.data?.message);
          }
        },
        (err) => {
          showErrorAlert(err.data?.message);
        }
      );
    },
  });

  const [DisabledField, setDisabledField] = useState(false);

  function Setdata(values) {
    setDisabledField(true);
    formik.setValues({
      name: values?.player_name,
      email: values?.email,
      phone: values?.phone,
      profile_id: values?.profile_id,
      description: values?.description,
      status: values?.status,
    });
    inputdata.current = values?.groupDetail;
    component.current = "player";
    setselectedState(values);
    setformsubmit(false);
  }

  useEffect(() => {
    getplayerlisting();
    // getCurrencylisting();
    // getprofilelisting();
  }, [currentPage,searchValue,userLimit]);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const getHeaderClassName = (columnName) => {
    if (columnName === sortColumn) {
      return `sorting_${sortDirection}`;
    }
    return "sorting";
  };

  const sortedData = playerData?.slice().sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortDirection === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  }); 

  const handlePhoneChange = (value) => {
    console.log(value);
    if (value && value.trim() !== "") {
      formik.setFieldValue("phone", value);
    }
  };

  function CheckTelLength(value) {
    // console.log(value)
    const phoneNumberObject = parsePhoneNumber(value);
    const nsn = phoneNumberObject ? phoneNumberObject.nationalNumber : "";

    // Check the length of the NSN
    const nsnLength = nsn.length;
    // console.log(nsn)
    // console.log(nsnLength)

    if (nsnLength >= 8 && nsnLength <= 15) {
      return false;
    } else {
      return true;
    }
  }

  const ExportToCSV = () => {
    const ExportArray = [];
    playerData.map((data) => ( 
      ExportArray.push({
        Id: data?.id,
        Date: data?.createdAt
          ? format(parseISO(data?.createdAt), "dd-MM-yyyy HH:mm")
          : "NO",
          Status:
          data?.status === 1
            ? "Active"
            : 'Inactive',

        Name: data?.name,
        Username : data?.username,
        Email: data?.email,
        Telephone: data?.phone,
       
        Connected: data?.groupDetail.map((entry,i) => `(${i+1}) ${entry?.name}`).join(" ") || 'N/O',
        Description : data?.description,
      
      })
    ));

    const keys = Object.keys(ExportArray[0]);

    // Create CSV content with keys as headers and data values as rows
    const csvContent =
      "data:text/csv;charset=utf-8," +
      keys.join(",") +
      "\n" +
      ExportArray.map((row) => keys.map((key) => row[key]).join(",")).join(
        "\n"
      );

    // Create a link element
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", "Players_List.csv");

    // Append the link to the body
    document.body.appendChild(link);

    // Click the link programmatically to trigger the download
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-6 d-flex">
              <h3 className="fs-30 text-secondprimary">Players</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <Link className="breadcrumb-item active">Players</Link>
                </ol>
              </span>
            </div>
            <div className="col-6 pr-5">
              <div className="d-flex justify-content-end">
                <NavLink to={"/panel/new_player"}>
                  <div
                    className="btn  bg-secondprimary rounded-sm text-white"
                    style={{ minWidth: "180px" }}
                  >
                    <i className="fa-solid fa-plus mr-1"></i>
                    Add Player
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                <div className="d-flex justify-content-between mb-4">
                            <div className="col-md-4 pl-2">
                              <label
                                htmlFor="search-input"
                                className="search-label"
                              >
                                <i
                                  className="fa fa-search"
                                  aria-hidden="true"
                                ></i>
                              </label>
                              <input
                                type="search" 
                                id="search-input"
                                className="form-control pl-5 form-control-sm"
                                placeholder="Search"
                                onChange={(e) => setsearchValue(e.target.value)}
                              />
                            </div> 

                            <div className="col-md-2">
                              <button
                                className="badge badge-outline-primary fa-1x rounded float-right"
                                onClick={ExportToCSV}
                              >
                                <i
                                  className="fa fa-upload"
                                  aria-hidden="true"
                                ></i>{" "}
                                Export
                              </button>
                            </div>
                          </div>
                  <div className="row px-3">
                    
                    <div className="table-responsive  col-12">
                      {!loading && (
                        <table className="table dataTable no-footer table-hover">
                          <thead>
                            <tr>
                              <th className="pr-2">S.No</th>
                              {/* <th
                                className={getHeaderClassName("id")}
                                onClick={() => handleSort("id")}
                              >
                                ID
                              </th> */}
                               <th
                                className={getHeaderClassName("status")}
                                onClick={() => handleSort("status")}
                              >
                                Status
                              </th>
                              <th
                                className={getHeaderClassName("player_name")}
                                onClick={() => handleSort("player_name")}
                              >
                                Name
                              </th>
                              {/* <th
                                className={getHeaderClassName("profile")}
                                onClick={() => handleSort("profile")}
                              >
                                Profile
                              </th> */}
                              <th
                                // className={getHeaderClassName("email")}
                                // onClick={() => handleSort("email")}
                              >
                                Email
                              </th>
                              <th
                                // className={getHeaderClassName("groupDetail")}
                                // onClick={() => handleSort("groupDetail")}
                              >
                                Connected
                              </th>
                             
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {playerData &&
                              sortedData?.map((item, i) => (
                                <tr key={i}>
                                  <td className=" ">
                                    <span className=" ">
                                      {i + currentPage * userLimit + 1}
                                    </span>
                                  </td>
                                  {/* <td>{item?.id}</td> */}
                                  <td>
                                    <span
                                      className={
                                        item?.status === 1
                                          ? "badge badge-success"
                                          : "badge badge-danger"
                                      }
                                    >
                                      {item?.status ? "Active" : "Inactive"}
                                    </span>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/panel/view_user/${item?.id}/player`}
                                    >
                                      {item?.name}
                                    </Link>
                                  </td>
                                  {/* <td>
                                    <Link
                                      to={`/panel/view_user/${item?.profile_id}/profile`}
                                    >
                                      {item?.profile
                                        ? item?.profile?.profile_name
                                        : "NO"}
                                    </Link>
                                  </td> */}
                                  <td>{item?.email || "NO"}</td>
                                  <td>
                                    {item?.groupDetail?.length > 0 && (
                                      <TooltipComponent
                                        data={item?.groupDetail}
                                      ></TooltipComponent>
                                    )}
                                    {item?.groupDetail?.length === 0 && (
                                      <span>NO</span>
                                    )}
                                  </td>
                                 
                                  <td>
                                    {/* <span
                                      data-toggle="modal"
                                      data-target="#AddModal"
                                      title="Edit"
                                      style={{ cursor: "pointer" }}
                                      className="mx-2"
                                      onClick={() => Setdata(item)}
                                    >
                                      <i class="fa-solid fa-pen"></i>
                                    </span> */}
                                     <span 
                                      title="Edit"
                                      style={{ cursor: "pointer" }}
                                      className="mx-2" 
                                    >
                                      <Link to={`/panel/update_player/${item?.id}`}>
                                      <i class="fa-solid fa-pen"></i>
                                      </Link>
                                    </span>
                                    <span className="toggle-switch">
                                      <Switch
                                        checked={item?.status}
                                        size="small"
                                        color="primary"
                                        onChange={() => {
                                          changeStatus(item?.id, item?.status);
                                        }}
                                        inputProps={{
                                          "aria-label": "toggle switch",
                                        }}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            {playerData && playerData.length === 0 && (
                              <tr>
                                <td colSpan="8" className="text-center">
                                  <h6 className="p-3"> No Data Found </h6>
                                </td>
                              </tr>
                            )}
                            {/* <tr>
                              <td colSpan={8}>
                                <div className="d-flex justify-content-end">
                                  <div
                                    className="add_circle bg-primary"
                                    data-toggle="modal"
                                    data-target="#AddModal"
                                    onClick={() => {
                                      handleReset();
                                      setselectedState("");
                                    }}
                                  >
                                    <i class="fa-solid fa-plus"></i>
                                  </div>
                                  <Link
                                    className="add_circle bg-primary" to={'/panel/new_player'} 
                                  >
                                    <i class="fa-solid fa-plus text-white"></i>
                                  </Link>
                                </div>
                              </td>
                            </tr> */}
                          </tbody>
                        </table>
                      )}

                      {loading ? (
                        <div className="LoaderSpinner">
                          <MoonLoader
                            color={"#003bff"}
                            loading={loading}
                            speedMultiplier={0.6}
                            size={50}
                          />
                          <div>Loading... Please wait.</div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="mt-4">
                        {/* <Pagination
                          counting={currentPage * userLimit}
                          totaldata={total}
                          pagecount={totalPages}
                          onChangePage={handlePageClick}
                          activePage={currentPage}
                        /> */}
                         <TablePagination
                          component="div"
                          count={total}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* / Add MODAL-------------------- / */}
      <div
        className="modal fade"
        id="AddModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body py-4">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-8 col-12">
                    <h4 className="modal-title h3 mb-3" id="exampleModalLabel">
                      Nome Player
                    </h4>
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.name && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          placeholder="Nome Player"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                      <div className="col-6">
                        <input
                          type="email"
                          className={`form-control ${
                            formik.errors.email && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          placeholder="Email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>
                    </div>
 

                    <div className="row mt-3">
                      <div className="col-6">
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="IT"
                          value={formik.values.phone} 
                          onChange={handlePhoneChange}
                          className={`form-control ${
                            formik.errors.phone && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          name="phone"
                          disabled={DisabledField}
                        /> 
                      </div>
                      {/* <div className="col-6">
                        <input
                          type="number"
                          className={`form-control ${
                            formik.errors.phone && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          placeholder="Telephone"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div> */}
                      <div className="col-6">
                        <select
                          className="form-control"
                          name="profile_id"
                          onChange={formik.handleChange}
                          value={formik.values?.profile_id}
                          disabled={DisabledField}
                        >
                          <option value={""} selected hidden>
                            Select Profilo
                          </option>
                          {profilolist &&
                            profilolist?.map((item, i) => (
                              <option value={item?.id} key={i}>
                                {item?.profile_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-12">
                        <textarea
                          placeholder="Descrizione"
                          className={`form-control ${
                            formik.errors.description && formsubmit
                              ? "border_error"
                              : ""
                          }`}
                          onBlur={formik.handleBlur}
                          name="description"
                          rows={2}
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-12 mt-4 mt-lg-0">
                    {/* <div className="d-flex justify-content-end mb-3">  */}
                    {/* <button
                        type="button"
                        className="close "
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleReset}
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </button>  */}
                    {/* </div>  */}
                    <div className="d-flex justify-content-between mb-2">
                      <h5
                        className="modal-title h4 mb-3 mx-2"
                        id="exampleModalLabel"
                      >
                        Gruppo associati
                      </h5>
                      <button
                        type="button"
                        className="close "
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleReset}
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div
                      className="px-2"
                      style={{ borderLeft: "1px solid #000" }}
                    >
                      <GroupSearch
                        inputdata={inputdata.current}
                        resetStateRef={resetStateRef.current}
                        component={component.current}
                        onSelectedOptionsChange={handleSelectedOptionsChange}
                      ></GroupSearch>
                      {/* <div className="mt-4 d-flex">
                        <input type="checkbox" />
                        <label className="h5 ml-2">Attiva Satellite</label>
                      </div> */}
                    </div>
                  </div>

                  <div className="col-12 mt-2 d-flex justify-content-end">
                    <span
                      className="add_circle bg-danger"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="closeModal"
                      onClick={handleReset}
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </span>
                    {!selectedState && (
                      <span
                        className="add_circle mx-2 bg-success"
                        onClick={() => setformsubmit(true)}
                      >
                        <button type="submit" className="btn text-white">
                          <i class="fa-solid fa-check"></i>
                        </button>
                      </span>
                    )}

                    {selectedState && DisabledField && (
                      <span
                        className="add_circle mx-2 bg-info"
                        onClick={() => setDisabledField(false)}
                      >
                        <button type="submit" className="btn text-white">
                          <i class="fa-solid fa-pen"></i>
                        </button>
                      </span>
                    )}

                    {selectedState && !DisabledField && (
                      <span
                        className="add_circle mx-2 bg-success"
                        onClick={() => setformsubmit(true)}
                      >
                        <button type="submit" className="btn text-white">
                          <i class="fa-solid fa-check"></i>
                        </button>
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* / Add MODAL- End ------------------- / */}

      {/* / DELETE MODAL-------------------- / */}
      <div
        className="modal fade"
        id="DeleteModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete User !
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6> Are you sure you want to delete this User ? </h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="closeDeleteModal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                // onClick={() => deleteuser()}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* / DELETE MODAL- End ------------------- / */}
    </div>
  );
}

import React from "react";
import { Button, List, ListItem, ListItemText, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const TooltipComponent = ({ data }) => {
  console.log(data);
  return (
    <Tooltip
      placement="left-start"
      arrow
      title={
        <div>
          <List>
            {data?.length > 0 ? (
              data?.map((listItem, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${index + 1}. ${
                      listItem?.name ||
                      listItem?.group?.name ||
                      listItem?.user?.name ||
                      listItem?.user_detail?.name ||
                      listItem?.refrents?.name ||
                      listItem?.mainUser?.name ||
                      listItem?.satellite_group?.name ||
                      listItem?.group_main_detail?.name
                    }`}
                  />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary={`No`} />
              </ListItem>
            )}
          </List>
        </div>
      }
    >
      <Button style={{ color: "#000", textTransform: "none" }}>
        {data?.length || "No"}
      </Button>
    </Tooltip>
  );
};

export default TooltipComponent;

const TooltipComponent2 = ({ data }) => {
  return (
    <Tooltip
      placement="left-start"
      arrow
      title={
        <div>
          <List>
            {data ? (
              <ListItem>
                <ListItemText primary={`${data}`} />
              </ListItem>
            ) : (
              <ListItem>
                <ListItemText primary={`No`} />
              </ListItem>
            )}
          </List>
        </div>
      }
    >
      <Button style={{ color: "#000", textTransform: "none" }}>
        {data ? `Slip#${data.substring(data.length - 5)}` : "NO"}
      </Button>
    </Tooltip>
  );
};

export { TooltipComponent2 };

const TooltipComponent4 = ({ data }) => {
  return (
    <Tooltip
      placement="left-start"
      arrow
      title={
        <div>
          <List> 
              <ListItem>
                <ListItemText primary={`${data?.slip ? data?.slip : data?.note}`} />
              </ListItem> 
          </List>
        </div>
      }
    >
      <Button style={{ color: "#000", textTransform: "none" }}>
        {data?.slip ? `Slip#${data?.slip.substring(data?.slip.length - 5)}` : data?.tag_detail?.name}
      </Button>
    </Tooltip>
  );
};

export { TooltipComponent4 };

const TooltipComponent3 = ({ data }) => {
  return (
    <Tooltip
      placement="left-start"
      arrow
      title={
        <div>
          <List>
            <ListItem>
              <ListItemText primary={`${data?.attach_group?.name}`} />
            </ListItem>
          </List>
        </div>
      }
    >
      <Button style={{ color: "#000", textTransform: "none" }}>
        {data?.role === "refrent" ? (
          <span className="badge badge-info">Referent</span>
        ) : data?.role === "player" ? (
          <span className="badge badge-primary">Player</span>
        ) : (
          "NO"
        )}
      </Button>
    </Tooltip>
  );
};

export { TooltipComponent3 };

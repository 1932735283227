/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../Sidebar/Sidebar.css";
export default function Sidebar() {
  const navigate = useNavigate();

  return (
    <div>
      <nav className="sidebar sidebar-offcanvas scrollsidebar" id="sidebar">
        <ul className="nav">
          {/* dashboard */}
          <li className="nav-item">
            <NavLink to={"/panel/dashboard"} className="nav-link">
              <i class="fa-solid fa-house menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </NavLink>
          </li>

          {/* Portfolio Report */}
          <li className="nav-item">
            <div className="nav-link">
              <i class="fa-solid fa-briefcase menu-icon"></i>
              <span className="menu-title">Portfolio Report</span>
            </div>
            {/* <NavLink to={"/panel/portfolio_report"} className="nav-link">
              <i class="fa-solid fa-briefcase menu-icon"></i>
              <span className="menu-title">Portfolio Report</span>
            </NavLink> */}
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              aria-expanded="false"
              href="#error"
              aria-controls="error"
            >
              <i class="fa-solid fa-chart-line menu-icon icon"></i>
              <span className="menu-title">Reports</span>
              <i className="menu-arrow icon" />
            </a>
            <div className="collapse" id="error">
              <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                  <NavLink to={"/panel/groups_balance"} className="nav-link"> 
                    <i class="fa-solid fa-list menu-icon"></i>
                    <span className="menu-title">Groups Balance</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={"/panel/transactions"} className="nav-link"> 
                    <i class="fa-solid fa-arrow-right-arrow-left menu-icon"></i>
                    <span className="menu-title">Transaction</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={"/panel/slips"} className="nav-link">
                    <i className="fa fa-database menu-icon" />
                    <span className="menu-title">Slips List</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              aria-expanded="false"
              href="#category"
              aria-controls="category"
            >
              <i className="icon-layout menu-icon icon" />
              <span className="menu-title">Anagraphics</span>
              <i className="menu-arrow icon" />
            </a>
            <div className="collapse" id="category">
              <ul className="nav flex-column sub-menu">
                {/* Group */}
          <li className="nav-item">
            <NavLink to={"/panel/group"} className="nav-link">
              <i class="fa-solid fa-users-viewfinder menu-icon"></i>
              <span className="menu-title">Groups</span>
            </NavLink>
          </li>

          {/* player */}
          <li className="nav-item">
            <NavLink to={"/panel/player"} className="nav-link">
              <i class="fa-solid fa-user menu-icon"></i>
              <span className="menu-title">Players</span>
            </NavLink>
          </li>

          {/* Profiles */}
          <li className="nav-item">
            {/* <div className="nav-link">
              <i class="fa-solid fa-id-card-clip menu-icon"></i>
              <span className="menu-title">Profiles</span>
            </div> */}
            <NavLink to={"/panel/profiles"} className="nav-link">
              <i class="fa-solid fa-id-card-clip menu-icon"></i>
              <span className="menu-title">Profiles</span>
            </NavLink>
          </li>

          {/* Referents */}
          <li className="nav-item">
            <NavLink to={"/panel/referents"} className="nav-link">
              <i class="fa-solid fa-user menu-icon"></i>
              <span className="menu-title">Referents</span>
            </NavLink>
          </li>

          {/* Collaborators */}
          <li className="nav-item">
            <NavLink to={"/panel/collaborators"} className="nav-link">
              <i class="fa-regular fa-handshake menu-icon"></i>
              <span className="menu-title">Collaborators</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={"/panel/wallets"} className="nav-link">
              <i class="fa-solid fa-wallet menu-icon"></i>
              <span className="menu-title">Wallets</span>
            </NavLink>
          </li>
              </ul>
            </div>
          </li>

          

          

          {/* player */}
          {/* <li className="nav-item">
            <NavLink to={"/panel/account_settings"} className="nav-link">
              <i class="fa-solid fa-gears menu-icon"></i>
              <span className="menu-title">Account Settings</span>
            </NavLink>
          </li> */}
        </ul>
      </nav>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import IconUser from "../../../../assets/img/IconUser.png";
import {
  GetService,
  PostFormData,
  PostService,
} from "../../../../services/ConstantService";
import { API_URL } from "../../../../services/ApiService";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import { useFormik } from "formik";
import { ImageURL } from "../../../../Environment/Environment";
import { parsePhoneNumber } from "react-phone-number-input";
import GroupSearch from "../../Layout/SearchGroup/SearchGroup";
import PhoneInput from "react-phone-number-input";

export default function AddNewReferent() {
  const navigate = useNavigate();
  const ID = useParams();

  const [loading, setLoading] = useState(false);
  const [formsubmit, setformsubmit] = useState(false);
  const [selectedState, setselectedState] = useState("");
  const [selectedOptions, setSelectedOptions] = useState("");
  const [profilolist, setprofilolist] = useState();
  const [playerlist, setplayerlist] = useState();
  const [collaboratorlist, setcollaboratorlist] = useState();
  const resetStateRef = useRef(false);
  const inputdata = useRef("");
  const component = useRef("referent");

  useEffect(() => {
    if (ID?.id) {
      setLoading(true);
      PostService(API_URL.REFERENTS_DETAILS, { id: ID?.id }).then(
        (res) => {
          if (res?.data?.status) {
            Setdata(res?.data?.data);
            setselectedState(res?.data?.data);
          } else {
            showErrorAlert(res.data?.message);
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          showErrorAlert(err.data?.message);
        }
      );
    }
  }, [ID?.id]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      profile_id: "",
      phone: "",
      status: 1,
      player_id: "",
      // collaborator_id: "",
      description: "",
      username: "",
      password: "",
      confirm_password: "",
      group: [],
    },
    validate: (values) => {
      console.log(values);
      const errors = {};

      if (!values.name) {
        errors.name = "Name is required";
      }

      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      if (!values.profile_id) {
        errors.profile_id = "profile_id is required";
      }

      if (!values.phone) {
        errors.phone = "Phone is required";
      } else if (CheckTelLength(values.phone)) {
        errors.phone = "Phone length";
      } 

      if (!values.player_id) {
        errors.player_id = "player_id is required";
      }

      // if (!values.collaborator_id) {
      //   errors.collaborator_id = "collaborator_id is required";
      // }

      if (!values.description) {
        errors.description = "description is required";
      }

      if (!values.username) {
        errors.username = "username is required";
      }

      if (!selectedState && !values.password) {
        errors.password = "Password is required";
      }

      if (!selectedState) {
        if (!values.confirm_password) {
          errors.confirm_password = "Confirm Password is required";
        } else if (values.confirm_password !== values.password) {
          errors.confirm_password = "Password mismatch";
        }
      }else{
        if (values.confirm_password !== values.password) {
          errors.confirm_password = "Password mismatch";
        }
      }
 
     
      return errors;
    },

    onSubmit: (values) => {
      console.log(values);
      setformsubmit(true);
      if (!formik.isValid) {
        return;
      }

      setLoading(true);
      const route = selectedState
        ? API_URL.UPDATE_REFERENCE
        : API_URL.CREATE_REFERENCE;
 
      const selectedGroupId = [];
      if (selectedOptions?.length > 0) {
        for (let i of selectedOptions) {
          selectedGroupId.push(i?.id);
        }
        values.group = selectedGroupId;
      } else {
        values.group = selectedGroupId;
      }

      const formdata = new FormData();
      if (selectedState) {
        formdata.append("id", selectedState?.id);
      }
      formdata.append("name", values.name)
      formdata.append("email", values.email); 
      formdata.append("contratii", values?.profile_id);
      formdata.append("phone", values.phone);
      formdata.append("status", values.status);
      formdata.append("player_id", values.player_id);
      // formdata.append("collabority", values.collaborator_id);
      formdata.append("description", values.description);
      formdata.append("username", values.username);
      formdata.append("password", values.password);
      formdata.append("confirm_password", values.confirm_password);
      formdata.append("group", selectedGroupId);
      formdata.append("image", selectedFile || "");

      PostFormData(route, formdata).then(
        (res) => {
          setLoading(false);
          if (res?.data?.status) {
            showSuccessAlert(res?.data?.message, NavigateUser);
          } else {
            showErrorAlert(res.data?.message);
          }
        },
        (err) => {
          setLoading(false);
          showErrorAlert(err.data?.message);
        }
      );
    },
  });

  const NavigateUser = () => {
    navigate("/panel/referents");
  };

  function Setdata(values) {
    formik.setValues({
      name: values?.name,
      email: values?.email,
      profile_id: values?.contratii_detail[0]?.profile_detail?.id,
      phone: values?.phone,
      status: values?.status,
      player_id: values?.player_detail[0]?.players?.id,  
      // collaborator_id: values?.collobority_detail[0]?.user_detail?.id,
      description: values?.description,
      username: values?.username,
      password: "",
      confirm_password: "",
    });
    inputdata.current = values?.groupDetail; 
    component.current = "referent";
    setselectedState(values);
    setformsubmit(false);

    setselectedFile(values?.image  || "");
    setSelectedImage(ImageURL + values?.image  || IconUser);
  }

  const handlePhoneChange = (value) => {
    console.log(value);
    if (value && value.trim() !== "") {
      formik.setFieldValue("phone", value);
    }
  };

  function CheckTelLength(value) {
    // console.log(value)
    const phoneNumberObject = parsePhoneNumber(value);
    const nsn = phoneNumberObject ? phoneNumberObject.nationalNumber : "";

    // Check the length of the NSN
    const nsnLength = nsn.length;
    // console.log(nsn)
    // console.log(nsnLength)

    if (nsnLength >= 8 && nsnLength <= 15) {
      return false;
    } else {
      return true;
    }
  }

  const handleResetButtonClick = () => {
    resetStateRef.current = !resetStateRef.current;
  };
  const handleSelectedOptionsChange = (options) => {
    setSelectedOptions(options);
  };

  const handleReset = () => {
    formik.resetForm();
    inputdata.current = "";
    setformsubmit(false);
    setSelectedOptions("");
    handleResetButtonClick();
  };

  const [selectedFile, setselectedFile] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (e) => {
    setselectedFile(e.target.files[0]);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
  };

  function getprofilelisting() {
    GetService(API_URL.PROFILES_LISTING_WITHOUT_PAGINATION).then((res) => {
      console.log(res);
      if (res?.data?.status) {
        setprofilolist(res?.data?.data);
      }
    });
  }

  function getplayerlisting() {
    GetService(API_URL.PLAYERS_LISTING_WITHOUT_PAGINATION).then((res) => {
      console.log(res);
      if (res?.data?.status) {
        setplayerlist(res?.data?.data);
      }
    });
  }

  // function getCollaboratorslisting() {
  //   GetService(`${API_URL.COLLABORATORS_LISTING_WITHOUT_PAGINATION}?role_id=3`).then((res) => {
  //     console.log(res);
  //     if (res?.data?.status) {
  //       setcollaboratorlist(res?.data?.data);
  //     }
  //   });
  // }

  useEffect(() => {
    getplayerlisting();
    getprofilelisting();
    // getCollaboratorslisting();
  }, []);

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12 d-flex">
              <h3 className="fs-30 text-secondprimary">
                {selectedState ? "Edit" : "New"} Referent
              </h3>
              <span>
                <ol className="breadcrumb ">
                  <Link to="/panel/dashboard" className="breadcrumb-item">
                    Home
                  </Link>
                  <Link to="/panel/referents" className="breadcrumb-item">
                    Referents
                  </Link>
                  <span className="breadcrumb-item active">{selectedState ? "Edit" : "New"} Referent</span>
                </ol>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row px-3">
                      <div className="col-5 pl-0">
                        <div className="groupPersonalCard rounded p-2 pl-3">
                          <div className="d-flex justify-content-center align-items-center">
                            <div
                              style={{ height: "98px", width: "98px" }}
                              className="overflow-hidden rounded-circle mr-3"
                            >
                              <label htmlFor="fileInput">
                                <span
                                  style={{
                                    position: "absolute",
                                    left: "25%",
                                    top: "5px",
                                  }}
                                >
                                  <i className="fa-solid fa-square-pen cursor"></i>
                                </span>{" "}
                              </label>
                              <img
                                src={selectedImage || IconUser}
                                alt="profile"
                                className="img-md h-100 w-100"
                                onError={(e) => {
                                  e.target.src = IconUser;
                                }}
                              />
                              <input
                                type="file"
                                id="fileInput"
                                style={{ display: "none" }}
                                onChange={(e) => handleFileChange(e)}
                              />
                            </div>
                            <div>
                              <input
                                type="text"
                                className={`form-control ${
                                  formik.errors.name && formsubmit
                                    ? "border_error"
                                    : ""
                                }`}
                                onBlur={formik.handleBlur}
                                placeholder="Name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                              ></input>
                            </div>
                          </div>{" "}
                          <hr className="groupPersonalCard m-2" />
                          <div className="d-flex mt-4">
                            <div className="col-7 pl-0">
                              <label className="text-label">Email</label>
                              <input
                                type="email"
                                className={`form-control form-control-sm ${
                                  formik.errors.email && formsubmit
                                    ? "border_error"
                                    : ""
                                }`}
                                onBlur={formik.handleBlur}
                                placeholder="Email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                              ></input>
                            </div>

                            <div className="col-5 pl-0">
                              <label className="text-label">Status</label>
                              <select
                                className={`form-control form-control-sm ${
                                  formik.errors.status && formsubmit
                                    ? "border_error"
                                    : ""
                                }`}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name="status"
                                value={formik.values.status}
                              >
                                <option value="" selected hidden>
                                  Status
                                </option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </select>
                            </div> 
                          </div>
                          <div className="d-flex mt-4">
                            <div className="col-7 pl-0 ">
                              <label className="text-label">Telephone</label>
                              <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="IT"
                                value={formik.values.phone}
                                onChange={handlePhoneChange}
                                className={`form-control form-control-sm ${
                                  formik.errors.phone && formsubmit
                                    ? "border_error"
                                    : ""
                                }`}
                                name="phone"
                              />
                            </div> 
                          </div>

                          <div className="col-7 pl-0 mt-2"> 
                              <label className="text-label">Profile</label>
                              <select
                                className={`form-control form-control-sm ${
                                  formik.errors.profile_id && formsubmit
                                    ? "border_error"
                                    : ""
                                }`}
                                onBlur={formik.handleBlur}
                                name="profile_id"
                                onChange={formik.handleChange}
                                value={formik.values.profile_id}
                              >
                                <option value={""} selected hidden>
                                  Select Profile
                                </option>
                                {profilolist &&
                                  profilolist?.map((item, i) => (
                                    <option value={item?.id} key={i}>
                                      {item?.profile_name}
                                    </option>
                                  ))}
                              </select> 
                          </div>

                          <div className="col-7 pl-0 mt-2">
                            <label className="text-label">Player</label>

                            <select
                              className={`form-control form-control-sm ${
                                formik.errors.player_id && formsubmit
                                  ? "border_error"
                                  : ""
                              }`}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="player_id"
                              value={formik.values.player_id}
                            >
                              <option value={""} selected hidden>
                                Select Player
                              </option>
                              {playerlist &&
                                playerlist?.map((item, i) => (
                                  <option value={item?.id} key={i}>
                                    {item?.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {/* <div className="col-7 pl-0 mt-2">
                            <label className="text-label">Collaborators</label>
                            <select
                               className={`form-control form-control-sm ${
                                formik.errors.collaborator_id && formsubmit
                                  ? "border_error"
                                  : ""
                              }`}
                              onBlur={formik.handleBlur}
                              name="collaborator_id"
                              onChange={formik.handleChange}
                              value={formik.values.collaborator_id}
                            >
                              <option value={""} selected hidden>
                                Select Collaborators
                              </option>
                              {collaboratorlist &&
                                collaboratorlist?.map((item, i) => (
                                  <option value={item?.id} key={i}>
                                    {item?.name}
                                  </option>
                                ))}
                            </select>
                          </div> */}
                          <div
                            className="col-12 d-flex p-0 mb-3"
                            style={{ marginTop: "95px" }}
                          >
                            <div className="col-6 p-0 pr-2">
                              {!selectedState && (
                                <button
                                  className="btn btn-sm bg-inverse-danger w-100 px-2 rounded"
                                  onClick={handleReset}
                                >
                                  <i class="fa fa-times" aria-hidden="true"></i>{" "}
                                  Undo
                                </button>
                              )}
                              {selectedState && (
                                <button
                                  className="btn btn-sm bg-inverse-danger w-100 px-2 rounded"
                                  onClick={NavigateUser}
                                >
                                  <i class="fa fa-times" aria-hidden="true"></i>{" "}
                                  Close
                                </button>
                              )}
                            </div>
                            <div className="col-6 p-0 pl-2">
                              <button
                                type="submit"
                                className="btn btn-sm bg-primary text-white w-100 px-2 rounded"
                                onClick={() => {
                                  setformsubmit(true);
                                }}
                              >
                                <i class="fa fa-check" aria-hidden="true"></i>{" "}
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-7 groupPersonalCard rounded">
                        <div className="">
                          <div className="mt-1">
                            <label className="text-label">Description</label>

                            <textarea
                              name="description"
                              rows="2"
                              className={`form-control ${
                                formik.errors.description && formsubmit
                                  ? "border_error"
                                  : ""
                              }`}
                              onBlur={formik.handleBlur}
                              value={formik.values.description}
                              onChange={formik.handleChange}
                              placeholder="Insert Description"
                            ></textarea>
                            <hr className="groupPersonalCard mx-0 my-2" />
                          </div>

                          <div className="col-12 p-0 my-3">
                            <div className="card pl-1" style={{border:'none'}}>
                              <div className="card-body groupPersonalCard px-2 rounded">
                                <label className="text-label">
                                  Account Connected
                                </label>

                                <div className="d-flex align-items-baseline pl-0 my-3">
                                  <label className="fs_12 p-0 font-weight-medium">
                                    Username:
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control form-control-sm ml-1 mr-3 ${
                                      formik.errors.username && formsubmit
                                        ? "border_error"
                                        : ""
                                    }`}
                                    placeholder="enter email address"
                                    name="username"
                                    value={formik.values.username}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                  ></input>
                                </div>

                                <div className="d-flex">
                                  <div className="col-5 d-flex align-items-baseline pl-0">
                                    <label className="fs_12 p-0 font-weight-medium">
                                      Password:
                                    </label>
                                    <input
                                      type="text"
                                      className={`form-control form-control-sm ml-2 ${
                                        !selectedState &&
                                        formik.errors.password &&
                                        formsubmit
                                          ? "border_error"
                                          : ""
                                      }`}
                                      placeholder="Password"
                                      name="password"
                                      value={formik.values.password}
                                      onBlur={formik.handleBlur}
                                      onChange={formik.handleChange}
                                    ></input>
                                  </div>
                                  <div className="col-7 d-flex align-items-baseline">
                                    <label className="fs_12 col-5 p-0 font-weight-medium">
                                      Confirm Password:
                                    </label>
                                    <input
                                      type="text"
                                      className={`form-control form-control-sm ${ 
                                        formik.errors.confirm_password &&
                                        formsubmit
                                          ? "border_error"
                                          : ""
                                      }`}
                                      onBlur={formik.handleBlur}
                                      placeholder="Confirm Password"
                                      name="confirm_password"
                                      value={formik.values.confirm_password}
                                      onChange={formik.handleChange}
                                      // disabled={DisabledField}
                                    ></input>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        { selectedState && <div className="col-12 p-0">
                            <div className="card pl-1" style={{border:'none'}}>
                              <div
                                className="card-body groupPersonalCard px-2 rounded"
                                style={{ height: "270px", overflow: "auto" }}
                              >
                                <label className="text-label">
                                  Connected Groups
                                </label>
                                <GroupSearch
                                  resetStateRef={resetStateRef.current}
                                  component={component.current}
                                  inputdata={inputdata.current}
                                  height="193px"
                                  onSelectedOptionsChange={
                                    handleSelectedOptionsChange
                                  }
                                ></GroupSearch>
                              </div>
                            </div>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </form>

                  {loading ? (
                    <div className="LoaderSpinner">
                      <MoonLoader
                        color={"#003bff"}
                        loading={loading}
                        speedMultiplier={0.6}
                        size={50}
                      />
                      <div>Loading... Please wait.</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import IconUser from "../../../../assets/img/IconUser.png";
import {
  GetService,
  PostFormData,
  PostService,
} from "../../../../services/ConstantService";
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from "../../../../Helper/SweetAlert";
import { useFormik } from "formik";
import { API_URL } from "../../../../services/ApiService";
import GroupSearch from "../../Layout/SearchGroup/SearchGroup";
import { MoonLoader } from "react-spinners";
import { ImageURL } from "../../../../Environment/Environment";

export default function AddWallet() {
  const navigate = useNavigate();
  const GroupId = useParams();

  const [formsubmit, setformsubmit] = useState(false);
  const [currencylist, setcurrencylist] = useState();
  const [playerlist, setplayerlist] = useState();
  const [referencelist, setreferencelist] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedState, setselectedState] = useState("");
  const [selectedOptions, setSelectedOptions] = useState("");
  const resetStateRef = useRef(false);
  const inputdata = useRef("");

  useEffect(() => {
    getCurrencylisting();
    getplayerlisting();
    // getreferencelisting();
  }, []);

  // console.log(groupData)
  function getCurrencylisting() {
    GetService(API_URL.GET_CURRENCY).then((res) => {
      console.log(res);
      if (res?.data?.status) {
        setcurrencylist(res?.data?.data);
      }
    });
  }

  function getplayerlisting() {
    GetService(API_URL.PLAYERS_LISTING_WITHOUT_PAGINATION).then((res) => {
      console.log(res);
      if (res?.data?.status) {
        setplayerlist(res?.data?.data);
      }
    });
  }
  function getreferencelisting(playerID) {
    PostService(API_URL?.PLAYER_DETAILS, { id: playerID }).then((res) => {
      console.log(res);
      if (res?.data?.status) {
        setreferencelist(res?.data?.data?.refrent_detail);
      }
    });
  }

  useEffect(() => {
    if (GroupId?.id) {
      setLoading(true);
      PostService(API_URL.GROUP_DETAIL, { id: GroupId?.id }).then(
        (res) => {
          if (res?.data?.status) {
            Setdata(res?.data?.data);
            setselectedState(res?.data?.data);
          } else {
            showErrorAlert(res.data?.message);
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          showErrorAlert(err.data?.message);
        }
      );
    }
  }, [GroupId?.id]);

  const formik = useFormik({
    initialValues: {
      name: "",
      status: 1,
      player_id: "",
      ref_id: "",
      category: "",
      tag: "",
      description: "",
      group: [],
      currency: "",
      amount: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Name is required";
      }
      if (!values.player_id) {
        errors.player_id = "player_id is required";
      }
      if (!values.ref_id) {
        errors.ref_id = "ref_id is required";
      }

      if (!values.category) {
        errors.category = "category is required";
      }

      if (!values.description) {
        errors.description = "description is required";
      }
      return errors;
    },

    onSubmit: (values) => {
      setformsubmit(true);
      if (!formik.isValid) {
        return;
      }

      if (!formik.values.tag) {
        showWarningAlert('Please select the Activity/Passivity tag');
        return;
      }

      setLoading(true);

      values.grand_total = GrandTotal;
      const selectedGroupId = [];
      if (selectedOptions?.length > 0) {
        for (let i of selectedOptions) {
          selectedGroupId.push(i?.id);
        }
        values.group = selectedGroupId;
      } else {
        values.group = selectedGroupId;
      }

      values.groupcurrency = AddMoreArray;

      const formdata = new FormData();
      if (selectedState) {
        formdata.append("id", selectedState?.id);
      }
      formdata.append("name", values.name);
      formdata.append("type", "wallet");
      formdata.append("status", values.status);
      formdata.append("player_id", values.player_id);
      formdata.append(
        "ref_id",
        values.ref_id === "player_wallet" ? 0 : values.ref_id
      );
      formdata.append(
        "playerwallet",
        values.ref_id === "player_wallet" ? 1 : 0
      );

      formdata.append("category", values?.category || "");

      formdata.append("tag", values.tag);
      formdata.append("description", values.description);
      formdata.append("image", selectedFile || "");

      // formdata.append("grand_total", GrandTotal);
      formdata.append("group", selectedGroupId);
      if (selectedState) {
        formdata.append("initialBalance", JSON.stringify(AddMoreArray));
      }else{
        formdata.append("groupcurrency", JSON.stringify(AddMoreArray));
      }

      const route = selectedState ? API_URL.UPDATE_GROUP : API_URL.CREATE_GROUP;
      PostFormData(route, formdata).then(
        (res) => {
          setLoading(false);
          if (res?.data?.status) {
            showSuccessAlert(res?.data?.message, NavigateUser);
          } else {
            showErrorAlert(res.data?.message);
          }
        },
        (err) => {
          setLoading(false);
          showErrorAlert(err.data?.message);
        }
      );
    },
  });

  const NavigateUser = () => {
    navigate("/panel/wallets");
  };

  function Setdata(values) {
    console.log(values);
    getreferencelisting(values?.player_id);
    formik.setValues({
      name: values?.name,
      status: values?.status,
      player_id: values?.player_id,
      ref_id: values?.playerwallet ? "player_wallet" : values?.ref_id,
      category: values?.category,
      tag: values?.tag,
      description: values?.description,
      currency: "",
      amount: "",
    });
    // inputdata.current = values?.group_detail;
    inputdata.current = ConnectedGroups(values);
    setformsubmit(false);
    if (values?.initialBalance?.length > 0) {
      setAddMoreArray(values?.initialBalance);
    }
    // setGrandTotal(Number(values?.grand_total));

    setselectedFile(values?.image || "");
    setSelectedImage(ImageURL + values?.image || IconUser);
  }

  const ConnectedGroups = (item) => {
    console.log(item);
    const groupsArray = [];
    item?.group_detail &&
      item?.group_detail.map((data) => groupsArray.push(data));
    item?.groups_detail &&
      item?.groups_detail.map((data) => groupsArray.push(data));

    return groupsArray;
  };
  
  const [AddMoreSubmit, setAddMoreSubmit] = useState(false);
  const [AddMoreLoader, setAddMoreLoader] = useState(false);
  const [AddMoreArray, setAddMoreArray] = useState([]);

  const addfunc = () => {
    if (!formik.values.currency || formik.values.amount === '') {
      // console.log("first");
      setAddMoreSubmit(true);
      return;
    }

    // if (formik.values.currency !== "EUR" && formik.values.amount) {
    //   convertInEUR(formik.values.amount, formik.values.currency, "add");
    // } else {
    //   setGrandTotal(
    //     Number((GrandTotal + Number(formik.values.amount)).toFixed(2))
    //   );
    // }

    const existingIndex = AddMoreArray.findIndex(
      (item) => item.currency === formik.values.currency
    );

    if (existingIndex !== -1) {
      // Update the existing entry
      const updatedArray = [...AddMoreArray];
    
      updatedArray[existingIndex] = {
        ...updatedArray[existingIndex],
        amount: updatedArray[existingIndex].amount + formik.values.amount
      };
      // console.log(updatedArray)
      setAddMoreArray(updatedArray); 
    } else {
      // Add a new entry
      setAddMoreArray([
        ...AddMoreArray,
        {
          amount: formik.values.amount,
          currency: formik.values.currency,
        }
      ]);
    }

    // AddMoreArray.push({
    //   amount: formik.values.amount,
    //   currency: formik.values.currency,
    // });

    console.log(AddMoreArray);
    formik.setFieldValue("currency", "");
    formik.setFieldValue("amount", "");
    setAddMoreSubmit(false);
  };

  const removefunc = (data, i) => {
    const newArray = [...AddMoreArray];
    newArray.splice(i, 1);
    setAddMoreArray(newArray);

    // if (data?.currency !== "EUR" && data?.amount) {
    //   convertInEUR(data?.amount, data?.currency, "remove");
    // } else {
    //   setGrandTotal(Number((GrandTotal - data?.amount).toFixed(2)));
    // }
  };

  const [GrandTotal, setGrandTotal] = useState(0);
  const [EURGrandTotal, setEURGrandTotal] = useState(0);

  const convertInEUR = (amount, currency, type) => {
    setAddMoreLoader(true);
    const obj = { currency: currency, currencyto: "EUR", amount: amount };
    PostService(API_URL.AMOUNT_CONVERSION, obj).then((res) => {
      if (res?.data?.status) {
        const num = res?.data?.data?.convertedAmount;
        if (type === "add") {
          setGrandTotal(Number((GrandTotal + num).toFixed(2)));
        } else {
          setGrandTotal(Number((GrandTotal - num).toFixed(2)));
        }
      }
      setAddMoreLoader(false);
    });
  };

  useEffect(() => {
    // console.log(GrandTotal);
    // setEURGrandTotal(Number((GrandTotal + GruppoGrandTotal).toFixed(2)));
    setEURGrandTotal(Number(GrandTotal.toFixed(2)));
  }, [GrandTotal]);

  //   const [GruppoGrandTotal, setGruppoGrandTotal] = useState(0);

  const handleSelectedOptionsChange = (options) => {
    console.log(options);
    // setGruppoGrandTotal(0);
    let amount = 0;
    for (let i = 0; i < options.length; i++) {
      const element = options[i];
      amount += Number(element?.grand_total);
      // console.log(amount  ,'--------------')
    }
    // console.log(GruppoGrandTotal)
    // setGruppoGrandTotal(amount);
    setSelectedOptions(options);
  };

  const [selectedFile, setselectedFile] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (e) => {
    setselectedFile(e.target.files[0]);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));

    // console.log("Selected file:", e.target.files[0]);
  };

  const OnTagClick = (tag) => {
    formik.setFieldValue("tag", tag);
  };

  const handleResetButtonClick = () => {
    resetStateRef.current = !resetStateRef.current;
  };

  const handleReset = () => {
    formik.resetForm();
    inputdata.current = "";
    setSelectedOptions("");
    setformsubmit(false);
    handleResetButtonClick();
    setAddMoreArray([]);
    setAddMoreSubmit(false);
    setGrandTotal(0);
    setEURGrandTotal(0);
    setAddMoreLoader(false);
    setSelectedImage(IconUser);
    setselectedFile("");
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12 d-flex">
              <h3 className="fs-30 text-secondprimary">
                {GroupId?.id ? "Edit" : "New"} Wallet
              </h3>
              <span>
                <ol className="breadcrumb ">
                  <Link to="/panel/dashboard" className="breadcrumb-item">
                    Home
                  </Link>
                  <Link to="/panel/wallets" className="breadcrumb-item">
                    Wallets
                  </Link>
                  <span className="breadcrumb-item active">
                    {GroupId?.id ? "Edit" : "New"} Wallet
                  </span>
                </ol>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row px-3">
                      <div className="col-5 pl-0">
                        <div className="groupPersonalCard rounded py-2 pl-3 pr-0">
                          <div className="d-flex justify-content-between align-items-center pr-3 ">
                            <div
                              style={{ height: "98px", width: "98px" }}
                              className="overflow-hidden rounded-circle mr-3"
                            >
                              <label htmlFor="fileInput">
                                <span
                                  style={{
                                    position: "absolute",
                                    left: "18%",
                                    top: "5px",
                                  }}
                                >
                                  <i className="fa-solid fa-square-pen cursor"></i>
                                </span>{" "}
                              </label>
                              <img
                                src={selectedImage || IconUser}
                                alt="profile"
                                className="img-md h-100 w-100"
                                onError={(e) => {
                                  e.target.src = IconUser;
                                }}
                              />
                              <input
                                type="file"
                                id="fileInput"
                                style={{ display: "none" }}
                                onChange={(e) => handleFileChange(e)}
                              />
                            </div>
                            <div>
                              <input
                                type="text"
                                className={`form-control ${
                                  formik.errors.name && formsubmit
                                    ? "border_error"
                                    : ""
                                }`}
                                onBlur={formik.handleBlur}
                                placeholder="Wallet Name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                              ></input>
                            </div>
                          </div>{" "}
                          <hr className="groupPersonalCard m-2" />
                          <div className="d-flex">
                            <div className="col-7 pl-0  ">
                              <label className="text-label">Category</label>
                              <select
                                className={`form-control form-control-sm ${
                                  formik.errors.category && formsubmit
                                    ? "border_error"
                                    : ""
                                }`}
                                onBlur={formik.handleBlur}
                                name="category"
                                onChange={formik.handleChange}
                                value={formik.values.category}
                              >
                                <option value="" selected hidden>
                                  Select Category
                                </option>
                                <option value="Cash">Cash</option>
                                <option value="Bank">Bank</option>
                                <option value="Crypto">Crypto</option>
                                <option value="Credit/Debit">
                                  Credit/Debit
                                </option>
                              </select>
                            </div>

                            <div className="col-5">
                              <label className="text-label">Status</label>
                              <select
                                className={`form-control form-control-sm ${
                                  formik.errors.status && formsubmit
                                    ? "border_error"
                                    : ""
                                }`}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name="status"
                                value={formik.values.status}
                              >
                                <option value="" selected hidden>
                                  Status
                                </option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-7 pl-0 mt-2">
                            <label className="text-label">Player</label>

                            <select
                              className={`form-control form-control-sm ${
                                formik.errors.player_id && formsubmit
                                  ? "border_error"
                                  : ""
                              }`}
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                formik.handleChange(e);
                                getreferencelisting(e.target.value);
                                formik.setFieldValue("ref_id", "");
                              }}
                              name="player_id"
                              value={formik.values.player_id}
                            >
                              <option value={""} selected hidden>
                                Select Player
                              </option>
                              {playerlist &&
                                playerlist?.map((item, i) => (
                                  <option value={item?.id} key={i}>
                                    {item?.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-7 pl-0 mt-2">
                            <label className="text-label">Referent</label>
                            <select
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldValue(
                                  "tag",
                                  e.target.value === "player_wallet"
                                    ? "Passivity"
                                    : ""
                                );
                              }}
                              value={formik.values.ref_id}
                              name="ref_id"
                              className={`form-control form-control-sm ${
                                formik.errors.ref_id && formsubmit
                                  ? "border_error"
                                  : ""
                              }`}
                              onBlur={formik.handleBlur}
                            >
                              <option value={""} selected hidden>
                                Select Referent
                              </option>
                              {!formik.values.player_id && (
                                <option value={""} disabled>
                                  Select Player first
                                </option>
                              )}
                              {formik.values.player_id &&
                                !referencelist?.length && (
                                  <option value={""} disabled>
                                    No referent found
                                  </option>
                                )}
                              {referencelist &&
                                referencelist?.map((item, i) => (
                                  <option value={item?.player_ref?.id} key={i}>
                                    {item?.player_ref?.name}
                                  </option>
                                ))}
                              {formik.values.player_id && (
                                <>
                                  <hr /> <hr />
                                  <option value="player_wallet">
                                    Player Wallet
                                  </option>
                                </>
                              )}
                            </select>

                            {formik.values.ref_id === "player_wallet" && (
                              <div
                                class="alert alert-warning alert-dismissible fade show d-flex align-items-center mt-1 px-2 py-1"
                                role="alert"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="currentColor"
                                  class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                  viewBox="0 0 16 16"
                                  role="img"
                                  aria-label="Warning:"
                                >
                                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg>
                                <div className="ml-2">
                                  Selecting a Player, is recommended you set as
                                  “Passivity” Wallet.
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-7 pl-0 mt-5 d-flex justify-content-between">
                            <button
                              type="button"
                              onClick={() => OnTagClick("Activity")}
                              className={`btn btn-sm rounded-pill ${
                                formik.values.tag === "Activity"
                                  ? "bg-success"
                                  : "bg-inverse-success"
                              }`}
                            >
                              Activity
                            </button>
                            <button
                              type="button"
                              onClick={() => OnTagClick("Passivity")}
                              className={`btn btn-sm rounded-pill ${
                                formik.values.tag === "Passivity"
                                  ? "bg-danger"
                                  : "bg-inverse-danger"
                              }`}
                            >
                              Passivity
                            </button>
                          </div>
                          <div className="col-12 mt-5 mb-2 d-flex p-0 pr-3">
                            <div className="col-6 p-0 pr-2">
                              {!selectedState && (
                                <button
                                  className="btn btn-sm bg-inverse-danger w-100 px-2 rounded"
                                  onClick={handleReset}
                                >
                                  <i class="fa fa-times" aria-hidden="true"></i>{" "}
                                  Undo
                                </button>
                              )}
                              {selectedState && (
                                <button
                                  className="btn btn-sm bg-inverse-danger w-100 px-2 rounded"
                                  onClick={NavigateUser}
                                >
                                  <i class="fa fa-times" aria-hidden="true"></i>{" "}
                                  Close
                                </button>
                              )}
                            </div>
                            <div className="col-6 p-0 pl-2">
                              <button
                                type="submit"
                                className="btn btn-sm bg-primary text-white w-100 px-2 rounded"
                                onClick={() => {
                                  setformsubmit(true);
                                }}
                              >
                                <i class="fa fa-check" aria-hidden="true"></i>{" "}
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* <div className="mt-auto">
                         
                        </div> */}
                      </div>

                      <div className="col-7 groupPersonalCard rounded">
                        <div className="">
                          <div className="mt-1">
                            <label className="text-label">Description</label>

                            <textarea
                              name="description"
                              rows="2"
                              className={`form-control ${
                                formik.errors.description && formsubmit
                                  ? "border_error"
                                  : ""
                              }`}
                              onBlur={formik.handleBlur}
                              value={formik.values.description}
                              onChange={formik.handleChange}
                              placeholder="Input text"
                            ></textarea>
                            <hr className="groupPersonalCard mx-0 my-2" />
                          </div>

                          <div className="d-flex mb-2">
                            <div className="col-6 p-0">
                              <div
                                className="card pr-1"
                                style={{ border: "none" }}
                              >
                                <div
                                  className="card-body groupPersonalCard px-2 rounded"
                                  style={{ height: "400px", overflow: "auto" }}
                                >
                                  <label className="text-label">
                                    Initial Balance
                                  </label>

                                  <div className="mt-3">
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="number"
                                        className={`form-control form-control-sm col-6 ${
                                          formik.values.amount === "" &&
                                          AddMoreSubmit
                                            ? "border_error"
                                            : ""
                                        }`}
                                        placeholder="Amount"
                                        onBlur={formik.handleBlur}
                                        name="amount"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                        // min={0}
                                        onWheel={(e) => e.target.blur()}
                                      />

                                      <select
                                        className={`form-control form-control-sm mx-2 ${
                                          formik.values.currency === "" &&
                                          AddMoreSubmit
                                            ? "border_error"
                                            : ""
                                        }`}
                                        onBlur={formik.handleBlur}
                                        name="currency"
                                        onChange={formik.handleChange}
                                        value={formik.values.currency}
                                      >
                                        <option value={""} selected hidden>
                                          Currency
                                        </option>
                                        {currencylist &&
                                          currencylist?.map((item, i) => (
                                            <option value={item} key={i}>
                                              {item}
                                            </option>
                                          ))}
                                      </select>
                                      <span
                                        className="add_circle bg-primary"
                                        type="button"
                                        onClick={() => addfunc()}
                                        style={{
                                          height: "30px",
                                          width: "115px",
                                        }}
                                      >
                                        <i class="fa-solid fa-plus"></i>
                                      </span>
                                    </div>
                                  </div>

                                  {AddMoreArray && (
                                    <>
                                      <div className="mt-3">
                                        {AddMoreArray.map((data, i) => (
                                          <div key={i} className="mt-2">
                                            <div className="d-flex align-items-center">
                                              <input
                                                type="text"
                                                className={`form-control form-control-sm col-6`}
                                                name="amount"
                                                value={data?.amount?.toLocaleString()}
                                                disabled
                                              ></input>

                                              <input
                                                type="text"
                                                className={`form-control form-control-sm mx-2`}
                                                name="currency"
                                                value={data?.currency}
                                                disabled
                                              />

                                              <span
                                                className="add_circle bg-danger"
                                                type="button"
                                                onClick={() =>
                                                  removefunc(data, i)
                                                }
                                                style={{
                                                  height: "25px",
                                                  width: "98px",
                                                }}
                                              >
                                                <i class="fa-solid fa-minus"></i>
                                              </span>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                      {/* <div className="mt-3">
                                        <div className="d-flex align-items-baseline">
                                          {AddMoreLoader && (
                                            <div
                                              class="spinner-border spinner-border-sm mt-3"
                                              role="status"
                                            ></div>
                                          )}
                                          <input
                                            type="text"
                                            value={EURGrandTotal}
                                            className="form-control form-control-sm col-4"
                                            readOnly
                                          />{" "}
                                          &nbsp;
                                          <p className="fs_12 h6 mt-3">
                                            : Grand Total (EUR)
                                          </p>
                                        </div>
                                      </div> */}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-6 p-0">
                              <div
                                className="card pl-1"
                                style={{ border: "none" }}
                              >
                                <div
                                  className="card-body groupPersonalCard px-2 rounded"
                                  style={{ height: "400px", overflow: "auto" }}
                                >
                                  <label className="text-label">
                                    Connected Groups
                                  </label>
                                  <GroupSearch
                                    resetStateRef={resetStateRef.current}
                                    inputdata={inputdata.current}
                                    height="300px"
                                    onSelectedOptionsChange={
                                      handleSelectedOptionsChange
                                    }
                                  ></GroupSearch>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  {loading ? (
                    <div className="LoaderSpinner">
                      <MoonLoader
                        color={"#003bff"}
                        loading={loading}
                        speedMultiplier={0.6}
                        size={50}
                      />
                      <div>Loading... Please wait.</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { GetService } from "../../../../services/ConstantService";
import { API_URL } from "../../../../services/ApiService";

const ReferentSearch = ({ onSelectedOptionsChange, resetStateRef, inputdata }) => {
  const [search, setSearch] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("");
  const [hideDropDown, setHideDropDown] = useState(true);
  const [referentsDataWithoutPagination, setReferentsDataWithoutPagination] = useState(
    []
  );
  const [selectedOptions, setSelectedOptions] = useState([]);

  const getGroupListingWithoutPagination = () => {
    if(search?.length>0){
      GetService(
        `${API_URL.REFERENTS_LISTING_WITHOUT_PAGINATION}?searchKey=${search}&role_id=4`
      ).then((res) => {
        if (res?.status) {
          setReferentsDataWithoutPagination(res?.data?.data);
        }
      });
    }
    
  };
  const handleResetState = () => {
    setSearch("");
    setSelectedSearch("");
    setHideDropDown(true);
    setReferentsDataWithoutPagination([]);
    setSelectedOptions([]);
    onSelectedOptionsChange([]);
  };
  const handleforEdit = () => {
    const data = inputdata;
    for (let i = 0; i < data?.length; i++) {
      handleOptionSelect(data[i]?.user);
    }
  };

  useEffect(() => {
    handleResetState();
  }, [resetStateRef]);

  useEffect(() => {
    handleforEdit();
  }, [inputdata]);

  useEffect(() => {
    getGroupListingWithoutPagination();
  }, [search]);

  const handleOptionSelect = (option) => {
    if (option?.id) {
      setSelectedOptions((prevOptions) => {
        const newOptions = [...prevOptions, option];
        onSelectedOptionsChange(newOptions);
        setSelectedSearch("");
        return newOptions;
      });
    }
  };

  const handleRemoveOption = (index) => {
    setSelectedOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.splice(index, 1);
      onSelectedOptionsChange(updatedOptions);
      setSelectedSearch("");
      return updatedOptions;
    });
  };

  return (
    <div className="card card-bg" style={{ height: "210px" }}>
      <div className="mt-2 mx-2 row">
        <div className="col-10 px-0 side_search">
          <input
            type="text"
            className="form-control"
            name="referents"
            onChange={(e) => {
              setSearch(e.target.value);
              setHideDropDown(true);
            }}
            value={search}
            style={{ paddingLeft: "30px" }}
            placeholder="Search Referents"
            autoComplete="off"
          />
          <div
            className="bg-white position-absolute rounded overflow-auto"
            style={{
              minHeight: "50px",
              maxHeight: "150px",
              zIndex: "1",
              width: "100%",
              top: "103%",
              display: search?.length > 0 && hideDropDown ? "block" : "none",
            }}
          >
            <div className="align-items-center d-flex flex-column justify-content-center py-2 drop">
              <ul className="px-3 w-100 list-inline">
                <p className="text-small text-center">
                  {referentsDataWithoutPagination?.length !== 0
                    ? ""
                    : "No Result Found"}
                </p>
                {referentsDataWithoutPagination &&
                  referentsDataWithoutPagination?.map((item, i) => (
                    <li
                      key={i}
                      className="px-2 py-1 text-center text-small rounded"
                      onClick={() => {
                        setSelectedSearch(item);
                        setSearch(item?.name+ ' '+ item?.lastname);
                        setHideDropDown(false);
                      }}
                    >
                      {item?.name+ ' '+ item?.lastname}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div
            className="position-absolute"
            style={{ top: "9px ", left: "10px" }}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </div>
        </div>
        <div className="col-2 px-1">
          <div
            className="add_circle mx-2 icon_size bg-primary"
            onClick={() => {
              handleOptionSelect(selectedSearch);
              setSearch("");
            }}
          >
            <i className="fa-solid fa-plus"></i>
          </div>
        </div>
      </div>
      <div className="mr-1 text-center mt-1 overflow-auto">
        {selectedOptions?.length === 0 && (
          <p className="h5 my-3 fs_12">No Referents Added</p>
        )}
        {selectedOptions.map((option, i) => (
          <div
            key={i}
            className="align-items-center badge badge-secondary d-flex justify-content-between mx-2 px-3 py-0 pt-1 mt-1"
          >
            <p>{option?.name+ ' '+ option?.lastname}</p>
            <i
              className="fa-solid fa-circle-xmark cursor"
              onClick={() => handleRemoveOption(i)}
            ></i>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReferentSearch;

import React, { useEffect } from "react";
import { Outlet, useNavigate } from 'react-router-dom';
import Header from "./Layout/Header/Header";
import Sidebar from "./Layout/Sidebar/Sidebar";
import Footer from "./Layout/Footer/Footer";
import { useState } from "react";

export default function Main() {
  const [isDark, setDark] = useState(() => localStorage.getItem("dark_mode") === "true");
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('token');

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const addClassToBody = (value) => {
    const bodyElement = document.body;
    if (bodyElement && value) {
      bodyElement.classList.add("dark_mode");
      localStorage.setItem("dark_mode", value.toString());
    } else {
      bodyElement.classList.remove("dark_mode");
      localStorage.removeItem("dark_mode");
    }
  };

  useEffect(() => {
    const localDarkMode = localStorage.getItem("dark_mode") === "true";
    setDark(localDarkMode);
    addClassToBody(localDarkMode);
  }, []);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <Header />
        <Sidebar />

        <div className="main-panel" style={{ height: '100vh', overflow: 'auto' }}>
          <div className="content-wrapper">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Switch } from "@mui/material";
import { API_URL } from "../../../../services/ApiService";
import { GetService, PostService } from "../../../../services/ConstantService";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../Helper/SweetAlert";
import { useFormik } from "formik";
import { MoonLoader } from "react-spinners";
import Pagination from "../../../../Helper/Pagination";
import TablePagination from "@mui/material/TablePagination";

const Setting = () => {
  const [isDark, setDark] = useState(
    () => localStorage.getItem("dark_mode") === "true"
  );

  const addClassToBody = (value) => {
    const bodyElement = document.body;
    if (bodyElement && value) {
      bodyElement.classList.add("dark_mode");
      localStorage.setItem("dark_mode", value.toString());
    } else {
      bodyElement.classList.remove("dark_mode");
      localStorage.removeItem("dark_mode");
    }
  };

  useEffect(() => {
    const localDarkMode = localStorage.getItem("dark_mode") === "true";
    setDark(localDarkMode);
    addClassToBody(localDarkMode);
  }, []);

  const [Page, setPage] = useState("Theme");

  const [TagList, setTagList] = useState();
  const [totalPages, setTotalPages] = useState();
  const [submit, setsubmit] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [userLimit, setUserLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [selectedState, setselectedState] = useState("");

  const [PagiPage, setPagiPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    setPagiPage(newPage);
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log(event.target.value);
    // console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setUserLimit(event.target.value);
    setCurrentPage(0);
    setPagiPage(0);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e - 1);
  };

  const handleReset = () => {
    formik.resetForm();
    setsubmit(false);
    setDisabledField(false);
  };

  const changeStatus = async (id, status) => {
    const data = {
      id: id,
      status: status === 1 ? 0 : 1,
    };

    PostService(API_URL.CHANGE_TAG_STATUS, data).then(
      (res) => {
        if (res.data.status) {
          showSuccessAlert(res?.data?.message, getTaglisting);
        } else {
          showErrorAlert(res.data?.message);
        }
      },
      (err) => {
        showErrorAlert(err.data?.message);
      }
    );
  };

  function getTaglisting() {
    setLoading(true);
    GetService(
      `${API_URL.TAG_LISTING}?page=${currentPage}&size=${userLimit}`
    ).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        setTagList(res?.data?.data?.list);
        setTotalPages(res.data?.data?.totalPages);
        setTotal(res?.data?.data?.totalItems);
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Name is required";
      }
      return errors;
    },

    onSubmit: (values) => {
      console.log(values);
      if (formik.isValid) {
        setLoading(true);
        const route = selectedState ? API_URL.UPDATE_TAG : API_URL.CREATE_TAG;
        if (selectedState) {
          values.id = selectedState?.id;
        }
        PostService(route, values).then(
          (res) => {
            setLoading(false);
            if (res?.data.status) {
              showSuccessAlert(res?.data?.message, getTaglisting);
              document.getElementById("closeModal").click();
            } else {
              showErrorAlert(res.data?.message);
            }
          },
          (err) => {
            showErrorAlert(err.data?.message);
          }
        );
      }
    },
  });

  const [DisabledField, setDisabledField] = useState(false);

  function Setdata(values) {
    setDisabledField(true);
    console.log(values);
    formik.setValues({
      name: values.name,
    });
    setselectedState(values);
  }

  useEffect(() => {
    if (Page === "Tags") {
      getTaglisting();
    }
  }, [currentPage, Page, userLimit]);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (columnName) => {
    if (columnName === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(columnName);
      setSortDirection("asc");
    }
  };

  const getHeaderClassName = (columnName) => {
    if (columnName === sortColumn) {
      return `sorting_${sortDirection}`;
    }
    return "sorting";
  };

  const sortedData = TagList?.slice().sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (sortDirection === "asc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const DeleteFunc = () => { 
    setLoading(true)
    PostService(API_URL.DELETE_TAG, {id:selectedState?.id}).then((res)=>{
      console.log(res)
      setLoading(false)
      if (res.data.status) {
        getTaglisting();
        document.getElementById('CloseModal').click()
      }
    });
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12 d-flex">
              <h3 className="fs-30">Settings</h3>
              {/* <span>
                <ol className="breadcrumb ">
                  <Link to="/panel/dashboard" className="breadcrumb-item" style={{ textDecoration: "none" }}>
                    Home
                  </Link>
                  <span className="breadcrumb-item active">Settings</span>
                </ol>
              </span> */}
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid">
        <div className="settingNav">
          <nav class="nav">
            <span
              class={`nav-link mr-5 ${Page === "Theme" ? "border-bottom" : ""}`}
              onClick={() => setPage("Theme")}
            >
              Theme
            </span>
            <span
              class={`nav-link ${Page === "Tags" ? "border-bottom" : ""}`}
              onClick={() => setPage("Tags")}
            >
              Tags
            </span>
          </nav>
        </div>
      </section>

      {Page === "Theme" && (
        <section className="content mt-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card stretch-card card-bg">
                  <div className="card-body">
                    <div className="row px-3">
                      <div className="col-12" style={{ height: "400px" }}>
                        <div>
                          <span className="h4 pr-3">Dark Theme</span>
                          <span className="toggle-switch">
                            <Switch
                              size="small"
                              color="primary"
                              checked={isDark}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                setDark(isChecked);
                                addClassToBody(isChecked);
                              }}
                              inputProps={{
                                "aria-label": "toggle switch",
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {Page === "Tags" && (
        <>
          <section className="content mt-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row px-3">
                        <div className="col-md-4 offset-md-8 p-0">
                          <nav className="navbar p-0"></nav>
                        </div>
                        <div class="table-responsive mt-4">
                          {!loading && (
                            <table class="table dataTable no-footer table-hover">
                              <thead>
                                <tr>
                                  <th>S.No</th>
                                  {/* <th
                                    className={getHeaderClassName("id")}
                                    onClick={() => handleSort("id")}
                                  >
                                    ID
                                  </th> */}
                                  <th
                                    className={getHeaderClassName("name")}
                                    onClick={() => handleSort("name")}
                                  >
                                    Tag Name
                                  </th>

                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {TagList &&
                                  sortedData?.map((item, i) => (
                                    <tr key={i}>
                                      <td>
                                        <span>
                                          {i + currentPage * userLimit + 1}
                                        </span>
                                      </td>
                                      {/* <td>{item?.id}</td> */}

                                      <td>{item?.name}</td>

                                      <td>
                                        <span
                                          className={
                                            item?.status === 1
                                              ? "badge badge-success"
                                              : "badge badge-danger"
                                          }
                                        >
                                          {item?.status ? "Active" : "Inactive"}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          data-toggle="modal"
                                          data-target="#AddModal"
                                          title="Edit"
                                          style={{ cursor: "pointer" }}
                                          className="mx-2"
                                          onClick={() => Setdata(item)}
                                        >
                                          <i class="fa-solid fa-pen"></i>
                                        </span>
                                        <span className="toggle-switch mx-2">
                                          <Switch
                                            checked={item?.status}
                                            size="small"
                                            color="primary"
                                            onChange={() => {
                                              changeStatus(
                                                item?.id,
                                                item?.status
                                              );
                                            }}
                                            inputProps={{
                                              "aria-label": "toggle switch",
                                            }}
                                          />
                                        </span>
                                        <span
                                          data-toggle="modal"
                                          data-target="#DeleteModal"
                                          className="mx-2"
                                          onClick={() => setselectedState(item)}
                                        >
                                          <i
                                            class="fa fa-trash text-danger cursor"
                                            aria-hidden="true"
                                            title="Delete"
                                          ></i>
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                {TagList && TagList.length === 0 && (
                                  <tr>
                                    <td colSpan="8" className="text-center">
                                      <h6 className="p-3"> No Data Found </h6>
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td colSpan={8}>
                                    <div className="d-flex justify-content-end">
                                      <div
                                        className="add_circle bg-primary"
                                        data-toggle="modal"
                                        data-target="#AddModal"
                                        onClick={() => {
                                          handleReset();
                                          setselectedState("");
                                        }}
                                      >
                                        <i class="fa-solid fa-plus"></i>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}

                          {loading ? (
                            <div className="LoaderSpinner">
                              <MoonLoader
                                color={"#003bff"}
                                loading={loading}
                                speedMultiplier={0.6}
                                size={50}
                              />
                              <div>Loading... Please wait.</div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="mt-4">
                            {/* <Pagination
                              counting={currentPage * userLimit}
                              totaldata={total}
                              pagecount={totalPages}
                              onChangePage={handlePageClick}
                              activePage={currentPage}
                            /> */}
                            <TablePagination
                              component="div"
                              count={total}
                              rowsPerPage={rowsPerPage}
                              page={PagiPage}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* / Add MODAL-------------------- / */}
          <div
            className="modal fade"
            id="AddModal"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div class="modal-header">
                  <h4 className="modal-title h3 mt-1" id="exampleModalLabel">
                    {selectedState ? "Update Tag" : "Add Tag"}
                  </h4>
                  <button
                    type="button"
                    className="close px-3"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleReset}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="modal-body py-4">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-9 col-12">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.name && submit ? "border_error" : ""
                          }`}
                          placeholder="Tag Name"
                          name="name"
                          value={formik.values.name}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          disabled={DisabledField}
                        ></input>
                      </div>

                      <div className="col-12 d-flex justify-content-end mt-3">
                        <span
                          className="add_circle bg-danger"
                          type="button"
                          data-dismiss="modal"
                          aria-label="Close"
                          id="closeModal"
                          onClick={handleReset}
                        >
                          <i class="fa-solid fa-xmark"></i>
                        </span>
                        {!selectedState && (
                          <span className="add_circle mx-2 bg-success">
                            <button
                              type="submit"
                              className="btn text-white"
                              onClick={() => setsubmit(true)}
                            >
                              <i class="fa-solid fa-check"></i>
                            </button>
                          </span>
                        )}

                        {selectedState && DisabledField && (
                          <span className="add_circle mx-2 bg-info">
                            <button
                              type="submit"
                              className="btn text-white"
                              onClick={() => setDisabledField(false)}
                            >
                              <i class="fa-solid fa-pen"></i>
                            </button>
                          </span>
                        )}

                        {selectedState && !DisabledField && (
                          <span
                            className="add_circle mx-2 bg-success"
                            onClick={() => setsubmit(true)}
                          >
                            <button type="submit" className="btn text-white">
                              <i class="fa-solid fa-check"></i>
                            </button>
                          </span>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* / Add MODAL- End ------------------- / */}

          {/* / DELETE MODAL-------------------- / */}
          <div
            className="modal fade"
            id="DeleteModal"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ borderRadius: "20px" }}>
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    Delete Tag !
                  </h3>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="CloseModal"
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <h4 className="font-weight-light">
                    Are you sure you want to delete this Tag?{" "}
                  </h4>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary d-none"
                    data-dismiss="modal"
                    id="closeDeleteModal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm rounded-lg"
                    onClick={DeleteFunc}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* / DELETE MODAL- End ------------------- / */}
        </>
      )}
    </div>
  );
};

export default Setting;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IconUser from "../../../../assets/img/IconUser.png";
import { useFormik } from "formik";
import MoonLoader from "react-spinners/MoonLoader";
import { GetService, PostFormData } from "../../../../services/ConstantService";
import { API_URL } from "../../../../services/ApiService";
import { ImageURL } from "../../../../Environment/Environment";
import { showErrorAlert, showSuccessAlert } from "../../../../Helper/SweetAlert";

export default function AccountSettings() {
  const [userData, setuserData] = useState();
  const [loading, setLoading] = useState(false);
  const [submit, setsubmit] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "", 
      // role: "",
    },
    validate: (values) => {
      const errors = {}; 

      if (!values.username) {
        errors.username = "User Name is required";
      } 

      if (!values.email) {
        errors.email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      } 

      return errors;
    },

    onSubmit: (values) => {
      console.log(values);
      console.log(selectedFile);

      const Formdata = new FormData() 
      
      Formdata.append('user_name',values?.username)
      Formdata.append('name',values?.username)
      Formdata.append('email',values?.email) 
      Formdata.append('profile_image',selectedFile) 

      PostFormData(API_URL.UPDATE_ADMIN_DETAILS , Formdata).then(
        (res) => {
          if (res?.data.status) {
            showSuccessAlert(res?.data?.message);  
          } else {
            showErrorAlert(res.data?.message);
          }
        },
        (err) => {
          showErrorAlert(err.data?.message);
        }
      );
    },
  });

  function getAdminDetails() {
    setLoading(true);
    GetService(API_URL.ADMIN_DETAILS).then((res) => {
      setLoading(false);
      if (res?.status) {
        const data = res?.data?.data;
        formik.setValues({
          username: data?.user_name,
          email: data?.email,
          // role: data?.role?.type,
        });
        if (data?.profile_image) {
          setSelectedImage(ImageURL + data?.profile_image);
          setselectedFile(data?.profile_image);
        }
      }
    });
  }
  useEffect(() => {
    getAdminDetails();
  }, []);

  const [selectedFile, setselectedFile] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (e) => {
    setselectedFile(e.target.files[0]);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));

    console.log("Selected file:", e.target.files[0]);
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12 d-flex">
              <h3 className="fs-30">Account</h3>
              <span>
                <ol className="breadcrumb ">
                  <Link
                    to="/panel/dashboard"
                    className="breadcrumb-item"
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <Link className="breadcrumb-item active">
                    Account Settings
                  </Link>
                </ol>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card stretch-card card-bg">
                <div className="card-body">
                  <div className="row px-3">
                    <div className="col-md-12 py-3">
                      <div className="d-flex py-3">
                        <div className="mr-3">
                          <div
                            style={{
                              height: "150px",
                              width: "150px",
                              backgroundColor: "darkgray",
                              overflow:'hidden'
                            }}
                            className="rounded-circle align-items-center d-flex justify-content-center rounded-circle"
                          >
                            <label htmlFor="fileInput">
                              {/* <img src={IconUser} alt="profile" /> */}
                              <img
                                src={selectedImage || IconUser}
                                alt="profile"
                                style={{ cursor: "pointer",height:'100%',width:'100%' }} 
                                onError={(e) => { e.target.src = IconUser}}
                              />
                            </label>
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: "none" }}
                              onChange={(e) => handleFileChange(e)}
                            />
                          </div>
                        </div>
                        <div className="ml-3 mt-3 ">
                          <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <input
                                  type="text"
                                  className={`form-control border ${
                                    formik.errors.username && submit ? "border_error" : ""
                                  }`} 
                                  placeholder="User Name"
                                  name="username"
                                  value={formik.values.username}
                                  onChange={formik.handleChange}
                                ></input>
                              </div>
                              <div className="col-12 my-2">
                                <input
                                  type="text"
                                  className={`form-control border ${
                                    formik.errors.email && submit ? "border_error" : ""
                                  }`} 
                                  placeholder="Email"
                                  name="email"
                                  value={formik.values.email}
                                  onChange={formik.handleChange}
                                ></input>
                              </div>
                              {/* <div className="col-12 my-2">
                                <input
                                  type="text"
                                  className="form-control border"
                                  placeholder="role"
                                  name="role"
                                  value={formik.values.role}
                                  onChange={formik.handleChange}
                                  readOnly
                                ></input>
                              </div> */}
                              <div className="col-12 d-flex justify-content-end mt-2">
                                <button className="btn btn-primary font-weight-bold" type="submit"  onClick={() => setsubmit(true)}>
                                  Update
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pb-5">
                      <h4 className="h3">Notifications</h4>
                    </div>
                    <div className="col-md-12 pb-5">
                      <h4 className="h3">Password</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="LoaderSpinner">
              <MoonLoader
                color={"#003bff"}
                loading={loading}
                speedMultiplier={0.6}
                size={50}
              />
              <div>Loading... Please wait.</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </div>
  );
}

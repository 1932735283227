import React from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./Admin/Main/Pages/Dashboard/Dashboard";
import Login from "./Admin/Login/Login";
import Main from "./Admin/Main/Main";
import { ToastContainer } from "react-toastify";
import ForgetPassword from "./Admin/Forget Password/ForgetPassword";

import ResetPassword from "./Admin/Reset Password/ResetPassword";
import Group from "./Admin/Main/Pages/Group/Group";
import Player from "./Admin/Main/Pages/Player/Player";
import Slips from "./Admin/Main/Pages/Slip/Slip";
import AccountSettings from "./Admin/Main/Pages/AccountSettings/Account_Settings";
import Setting from "./Admin/Main/Pages/Settings/Settings";
import CreateSlip from "./Admin/Main/Pages/Slip/Create_slip";
import Collaborators from "./Admin/Main/Pages/Collaborators/Collaborators";
import Referents from "./Admin/Main/Pages/Referents/Referents";
import Wallets from "./Admin/Main/Pages/Wallets/Wallets";
import Transaction from "./Admin/Main/Pages/Transaction/Transaction";
import Profiles from "./Admin/Main/Pages/Profilo/Profilo";
import Viewuser from "./Admin/Main/Layout/View_User/View_user";
import ViewGroup from "./Admin/Main/Pages/Group/ViewGroup";
import ViewWallet from "./Admin/Main/Pages/Wallets/ViewWallet";
import AddNewGroup from "./Admin/Main/Pages/Group/AddNewGroup";
import AddNewPlayer from "./Admin/Main/Pages/Player/AddNewPlayer";
import AddNewReferent from "./Admin/Main/Pages/Referents/AddNewReferent";
import AddNewCollaborator from "./Admin/Main/Pages/Collaborators/AddNewCollaborator";
import AddWallet from "./Admin/Main/Pages/Wallets/AddWallet";
import ViewSlip from "./Admin/Main/Pages/Slip/ViewSlip";
import GroupsBalance from "./Admin/Main/Pages/Groups Balance/GroupsBalance";

function App() {
  return (
    <div className="container-scroller">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />

        <Route path="/panel" element={<Main />}>
          <Route path="" element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="group" element={<Group />} />
          <Route path="new_group" element={<AddNewGroup />} />
          <Route path="update_group/:id" element={<AddNewGroup />} />
          <Route path="view_group/:id/:type" element={<ViewGroup />} />

          <Route path="player" element={<Player />} />
          <Route path="new_player" element={<AddNewPlayer />} />
          <Route path="update_player/:id" element={<AddNewPlayer />} />

          <Route path="referents" element={<Referents />} />
          <Route path="new_referent" element={<AddNewReferent />} />
          <Route path="update_referent/:id" element={<AddNewReferent />} />

          <Route path="collaborators" element={<Collaborators />} />
          <Route path="new_collaborator" element={<AddNewCollaborator />} />
          <Route path="update_collaborator/:id" element={<AddNewCollaborator />} />

          <Route path="view_user/:id/:type" element={<Viewuser />} />

          <Route path="wallets" element={<Wallets />} />
          <Route path="new_wallet" element={<AddWallet />} />
          <Route path="update_wallet/:id" element={<AddWallet />} />
          <Route path="view_wallet/:id/:type" element={<ViewWallet />} />
          {/* <Route path="view_wallet/:id" element={<ViewWallet />} /> */}

          <Route path="profiles" element={<Profiles />} />

          <Route path="slips" element={<Slips />} />
          <Route path="view_slip/:id" element={<ViewSlip />} />

          <Route path="groups_balance" element={<GroupsBalance />} />
          <Route path="transactions" element={<Transaction />} />

           <Route path="create_slip" element={<CreateSlip />} />
          <Route path="account_settings" element={<AccountSettings />} />
          <Route path="settings" element={<Setting />} />
          

        </Route>

        <Route path="*" element={<Navigate to="login" />} />
      </Routes>

      <ToastContainer />
    </div>
  );
}

export default App;
